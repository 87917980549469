import React from 'react';

import { EventDescription } from '../../components/events';
import { Table } from '../../components/table';

const columns = [
	{
		field: 'timestamp',
		label: 'Timestamp',
		format: 'timestamp',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'type',
		label: 'Type',
	},
	{
		field: 'description',
		label: 'Description',
		cell: (_, event) => {
			return <EventDescription event={event} />
		},
	}
];

const filters = [
	{
		field: 'fromDate',
		type: 'date',
		label: 'From date',
	},
	{
		field: 'untilDate',
		type: 'date',
		label: 'Until date',
	},
	{
		field: 'type',
		type: 'select',
		values: [
			{ value: '', label: '(please select)' },
			{ value: 'RunnerSignedUp', label: 'RunnerSignedUp' },
			{ value: 'RunnerTerminated', label: 'RunnerTerminated' },
			{ value: 'EmailAddressConfirmed', label: 'EmailAddressConfirmed' },
			{ value: 'EmailAddressUpdated', label: 'EmailAddressUpdated' },
			{ value: 'PersonalDetailsUpdated', label: 'PersonalDetailsUpdated' },
			{ value: 'RemindersUpdated', label: 'RemindersUpdated' },
			{ value: 'ProfileImageUploaded', label: 'ProfileImageUploaded' },
			{ value: 'ProfileImageDeleted', label: 'ProfileImageDeleted' },
			{ value: 'PlanCreated', label: 'PlanCreated' },
			{ value: 'PlanPaused', label: 'PlanPaused' },
			{ value: 'PlanUnpaused', label: 'PlanUnpaused' },
			{ value: 'TrainingDaysUpdated', label: 'TrainingDaysUpdated' },
			{ value: 'PlanTerminated', label: 'PlanTerminated' },
			{ value: 'PlanCompletionConfirmed', label: 'PlanCompletionConfirmed' },
			{ value: 'ExerciseDone', label: 'ExerciseDone' },
			{ value: 'ExerciseUndone', label: 'ExerciseUndone' },
			{ value: 'ExerciseFavorited', label: 'ExerciseFavorited' },
			{ value: 'ExerciseUnfavorited', label: 'ExerciseUnfavorited' },
			{ value: 'ExerciseFeedbackGiven', label: 'ExerciseFeedbackGiven', },
			{ value: 'PurchaseStarted', label: 'PurchaseStarted' },
			{ value: 'PurchaseCompleted', label: 'PurchaseCompleted' },
			{ value: 'PurchaseCanceled', label: 'PurchaseCanceled' },
		],
	}
];

const EventList = () => {
	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Events</h1>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="py-4">
					<Table
						url="/api/v1/admin/event"
						columns={columns}
						filters={filters}
						onEditClick={(row) => /*history.push(generatePath(ROUTES.EXERCISE_EDIT, { exerciseId: row.id }))*/{}}
					/>
				</div>
			</div>
		</>
	);
};

export default EventList;
