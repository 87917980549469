import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';

const NavLinkEx = ({ children, ...props }) => {
	let location = useLocation();
	const isActive = location.pathname === props.to;
	return (
		<NavLink {...props}>
			{children({ isActive })}
		</NavLink>
	);
};

export default NavLinkEx;
