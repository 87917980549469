import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { Input } from '../../components/forms';
import { ROUTES } from '../../constants';
import EditIntensityCategoryItemModal from './EditIntensityCategoryItemModal';

const IntensityCategoryEditSchema = Yup.object().shape({
	title: Yup.string()
		.required('Please enter a title.')
		.max(64, 'Please enter a shorter title.'),
});

const IntensityCategoryEdit = ({ match }) => {
	const [intensityCategory, setIntensityCategory] = useState();
	const [intensityCategoryItems, setIntensityCategoryItems] = useState([]);
	const [showEditIntensityCategoryItemModal, setShowEditIntensityCategoryItemModal] = useState(false);
	const [intensityCategoryItemToEdit, setIntensityCategoryItemToEdit] = useState(null);
	const { intensityCategoryId } = match.params;

	useEffect(() => {
		const loadIntensityCategory = async () => {
			const [
				{ data: { data: intensityCategory } },
				{ data: { data: intensityCategoryItems } },
			] = await Promise.all([
				 axios.get(`/api/v1/admin/intensity/category/${intensityCategoryId}`),
				 axios.get(`/api/v1/admin/intensity/category/${intensityCategoryId}/items`),
			]);
			setIntensityCategory(intensityCategory);
			setIntensityCategoryItems(intensityCategoryItems);
		}
		loadIntensityCategory();
	}, [intensityCategoryId]);

	const handleSubmit = async (values, { setSubmitting }) => {
	};

	const startIntensityCategoryItemEditing = (intensityCategoryItem) => {
		setIntensityCategoryItemToEdit(intensityCategoryItem);
		setShowEditIntensityCategoryItemModal(true);
	};

	const updateIntensityCategoryItem = async (values, { setSubmitting }) => {
		let pace = values.pace;
		if (pace) {
			const items = pace.split(':').map((item) => parseInt(item.trim()));
			if (items.length === 2) {
				pace = items[0] * 60 + items[1];
			} else {
				pace = items[0];
			}
		}
		console.log('####### updateIntensityCategoryItem label = ' + values.label + ', pace = ' + pace);
		try {
			setSubmitting(true);
			const { data: { data: updatedItem } } = await axios.post(`/api/v1/admin/intensity/category/${intensityCategoryId}/items/${values.intensity}`, {
				label: values.label,
				pace,
			});
			setIntensityCategoryItems(intensityCategoryItems.map((item) => item.intensity === updatedItem.intensity ? updatedItem : item));
			setShowEditIntensityCategoryItemModal(false);
			toast.success('Intensity "' + values.intensity + '" successfully updated.');
		} catch {
			toast.error('An unknown error occurred!');
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Edit Intensity Category {intensityCategory?.id}</h1>
				</div>
			</div>
			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					{intensityCategory && (
						<Formik
							initialValues={intensityCategory}
							validationSchema={IntensityCategoryEditSchema}
							validateOnBlur={false}
							onSubmit={handleSubmit}
						>
							{({ isSubmitting }) => (
								<Form disabled={isSubmitting}>
									<div className="divide-y divide-gray-200">
										<div>
											<div className="grid grid-cols-2 gap-x-4 pb-4">
												<Input id="id" label="ID" name="id" disabled />
												<Input id="creationDateTime" label="Created" name="creationDateTime" disabled />
											</div>
											<Input className="pb-4" id="status" label="Status" name="status" disabled />
											<Input className="pb-4" id="title" label={<span>Title<sup>*</sup></span>} name="title" />
										</div>
										<div className="bt-1 pt-8">
											<div className="flex justify-end">
												<Link
													as="button"
													to={ROUTES.PRICE_TIER_LIST}
													className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Cancel
												</Link>
												<button
													type="submit"
													className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Save
												</button>
											</div>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					)}
				</div>
			</div>

			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				{intensityCategoryItems && (
					<table className="min-w-full divide-y divide-gray-200 overflow-hidden shadow sm:rounded-lg">
						<thead className="bg-gray-50">
							<tr>
								<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Intensity</th>
								<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Label</th>
								<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pace</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{intensityCategoryItems.map((intensityCategoryItem, index) => (
								<tr key={`category-${intensityCategory.id}-item-${index}`} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{intensityCategoryItem.intensity}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{intensityCategoryItem.label}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{intensityCategoryItem.pace ? ~~(intensityCategoryItem.pace / 60) + ':' + ('0' + intensityCategoryItem.pace % 60).slice(-2) : ''}</td>
									<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
										<button className="text-cyan-600 hover:text-cyan-900" onClick={() => startIntensityCategoryItemEditing(intensityCategoryItem)}>Edit</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>

			<EditIntensityCategoryItemModal
				show={showEditIntensityCategoryItemModal}
				intensityCategoryItem={intensityCategoryItemToEdit}
				onHide={() => setShowEditIntensityCategoryItemModal(false)}
				onSubmit={updateIntensityCategoryItem}
			/>
		</div>
	);
};

export default IntensityCategoryEdit;
