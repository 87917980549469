import React from 'react';
import { format } from 'date-fns';
import { Link, generatePath } from 'react-router-dom';
import classNames from 'classnames';

import { ROUTES, ISO_DATE } from '../../constants';

const CalendarDay = ({ plan, day, constrainHeight }) => {

	const dateFormatted = format(day.date, ISO_DATE);
	const trainingDay = plan.details.trainingDays.find((trainingDay) => trainingDay.date === dateFormatted);

	return (
		<div style={{ minHeight: '8rem' }} className={classNames('flex', constrainHeight && 'h-32')}>
			<div className={classNames(
				'w-9 p-2 flex-none text-lg font-medium',
				trainingDay ? 'text-cyan-600' : 'text-gray-500'
			)}>{day.day}</div>

			{trainingDay && (
				<ul className="flex-1 p-2 overflow-auto">
					{trainingDay.exercises.map((exercise, index) => {
						const done = plan.done.some((doneExercise) => doneExercise.date === dateFormatted && doneExercise.identifier === exercise.identifier);
						return (
							<li key={`${day.date}-${index}-${exercise.exerciseId}`} className={classNames('text-gray-900', done && 'line-through')}>
								<Link to={generatePath(ROUTES.EXERCISE_EDIT, { exerciseId: exercise.exerciseId })}>{exercise.identifier}</Link>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default CalendarDay;
