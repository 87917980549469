import React, { useEffect, useState } from 'react';

import axios from '../../api/axios';

const PlanConfigStatistics = ({ planConfig }) => {
	const [planStatistics, setPlanStatistics] = useState();

	useEffect(() => {
		const loadPlanStatisticsAsync = async () => {
			const {
				data: { data: result }
			} = await axios.request(`/api/v1/admin/planstatistic/${planConfig.id}`);
			setPlanStatistics(result);
		};
		loadPlanStatisticsAsync();
	}, [planConfig.id]);

	if (!planStatistics) {
		return <p>Loading....</p>;
	} else {
		return (
			<table className="min-w-full divide-y divide-gray-200">
				<tbody>
					<tr className="bg-gray-50">
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobilization#</th>
						<td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{planStatistics.totalNumberOfMobilization}</td>
					</tr>
					<tr className="bg-white">
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Power#</th>
						<td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{planStatistics.totalNumberOfPower}</td>
					</tr>
					<tr className="bg-gray-50">
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Coordination#</th>
						<td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{planStatistics.totalNumberOfCoordination}</td>
					</tr>
					<tr className="bg-white">
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Warm-up/-down Minutes</th>
						<td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{planStatistics.totalWarmUpDownMinutes}</td>
					</tr>
					<tr className="bg-gray-50">
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Run Minutes</th>
						<td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{planStatistics.totalRunMinutes}</td>
					</tr>
				</tbody>
			</table>
		);
	}
};

export default PlanConfigStatistics;
