import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { Input, Switch } from '../../components/forms';
import { ROUTES } from '../../constants';

const RunnerEditSchema = Yup.object().shape({
	email: Yup.string().email(),
	firstName: Yup.string(),
	lastName: Yup.string(),
	dateOfBirth: Yup.string(),
	height: Yup.string(),
	weight: Yup.string(),
});

const PersonalDetails = ({ runner, onUpdateRunner }) => {
	return (
		<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
			{runner && (
				<Formik
					initialValues={{
						...runner,
						lastName: runner.lastName || '',
						height: runner.height || '',
						weight: runner.weight || '',
					}}
					validationSchema={RunnerEditSchema}
					validateOnBlur={false}
					onSubmit={onUpdateRunner}
					enableReinitialize
				>
					{({ isSubmitting }) => (
						<Form disabled={isSubmitting}>
							<div className="divide-y divide-gray-200">
								<div>
									<div className="grid grid-cols-2 gap-x-4 pb-4">
										<Input id="id" label="ID" name="id" disabled />
										<Input id="creationDateTime" label="Created" name="creationDateTime" disabled />
									</div>
									<Input className="pb-4" id="status" label="Status" name="status" disabled />
									<Input className="pb-4" id="email" label="Email" name="email" disabled={isSubmitting} />
									<div className="grid grid-cols-2 gap-x-4 pb-4">
										<Input id="firstName" label="First name" name="firstName" disabled={isSubmitting} />
										<Input id="lastName" label="Last name" name="lastName" disabled={isSubmitting} />
									</div>
									<div className="grid grid-cols-3 gap-x-4 pb-4">
										<Input id="dateOfBirth" label="DoB" name="dateOfBirth" disabled={isSubmitting} />
										<Input id="height" label="Height (cm)" name="height" disabled={isSubmitting} />
										<Input id="weight" label="Weight (kg)" name="weight" disabled={isSubmitting} />
									</div>
									<Switch className="pb-4" id="emailRemindersOn" label="Email reminders" name="emailRemindersOn" disabled={isSubmitting} />
									<Switch className="pb-4" id="pushRemindersOn" label="Push reminders" name="pushRemindersOn" disabled={isSubmitting} />
								</div>
								<div className="bt-1 pt-8">
									<div className="flex justify-end">
										<Link
											as="button"
											to={ROUTES.PLAN_CONFIG_LIST}
											className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
										>
											Cancel
										</Link>
										<button
											type="submit"
											className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
										>
											Update
										</button>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
};

export default PersonalDetails;
