import React from 'react';
import classNames from 'classnames';
import {
	HomeIcon,
	ChartSquareBarIcon,
	CurrencyDollarIcon,
	TableIcon,
	DocumentTextIcon,
	UsersIcon,
	ShareIcon,
	CreditCardIcon,
	InformationCircleIcon,
	EmojiHappyIcon,
} from '@heroicons/react/outline';
import { Image, Calendar } from 'react-feather';

import NavLinkEx from '../NavLinkEx';
import { ROUTES } from '../../constants';

const Header = ({ label }) => (
	<h2 className="px-2 pt-6 text-xs text-gray-600 font-bold uppercase">{label}</h2>
);

const NavLink = ({ to, icon: Icon, iconClassName, label }) => (
	<NavLinkEx
		to={to}
		activeClassName="bg-gray-100 text-cyan-500 hover:text-cyan-600"
		className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
	>
		{({ isActive }) => (
			<>
				<Icon
					className={classNames(
						iconClassName,
						isActive ? 'text-cyan-500 group-hover:text-cyan-600' : 'text-gray-400 group-hover:text-gray-500',
						'mr-3 flex-shrink-0 h-6 w-6'
					)}
					aria-hidden="true"
				/>
				{label}
			</>
		)}
	</NavLinkEx>
);

const Navigation = () => (
	<>
		<NavLink to={ROUTES.DASHBOARD} icon={HomeIcon} label="Dashboard" />

		<Header label="Configuration" />
		<NavLink to={ROUTES.PRICE_TIER_LIST} icon={CurrencyDollarIcon} label="Price Tiers" />
		<NavLink to={ROUTES.INTENSITY_CATEGORY_LIST} icon={ChartSquareBarIcon} label="Intensity Categories" />
		<NavLink to={ROUTES.PLAN_CONFIG_LIST} icon={TableIcon} label="Plan Configurations" />
		<NavLink to={ROUTES.EXERCISE_LIST} icon={DocumentTextIcon} label="Exercises" />
		<NavLink to={ROUTES.CHOICES} icon={ShareIcon} label="Choices" />
		<NavLink to={ROUTES.MEDIA_LIST} icon={Image} iconClassName="p-px" label="Media" />

		<Header label="Users" />
		<NavLink to={ROUTES.RUNNER_LIST} icon={UsersIcon} label="Runners" />
		<NavLink to={ROUTES.PLAN_LIST} icon={Calendar} iconClassName="p-px" label="Plans" />
		<NavLink to={ROUTES.PURCHASE_LIST} icon={CreditCardIcon} label="Purchases" />
		<NavLink to={ROUTES.EXERCISE_FEEDBACK_LIST} icon={EmojiHappyIcon} label="Feedback" />

		<Header label="System" />
		<NavLink to={ROUTES.EVENT_LIST} icon={InformationCircleIcon} label="Events" />

	</>
);

export default Navigation;
