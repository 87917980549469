import React from 'react';

import TitleItem from './TitleItem';
import ChoiceItem from './ChoiceItem';

const ChoiceColumn = ({ choices = [], selectedChoices = [], level, planConfigs, parentId, onCreate, onUpdate, onSelect, onMove, onDelete }) => {
	const titleForColumn = choices.find((choice) => choice.parentId === parentId && choice.type === 'Title');
	const choicesForColumn = choices.filter((choice) => choice.parentId === parentId && choice.type === 'Choice')
								.sort((c1, c2) => c1.rank - c2.rank);
	return (
		<div className="w-1/4 inline-block mr-2">
			<h4 className="text-center m-4">Level {selectedChoices.indexOf(parentId) + 1}</h4>
			<TitleItem
				className="Choices-Card"
				parentId={parentId}
				title={titleForColumn}
				onSubmit={titleForColumn ? onUpdate : onCreate}
			/>
			{planConfigs.length > 0 && (
				<>
					{choicesForColumn.map((choice) => (
						<ChoiceItem
							key={`choice-${choice.id}`}
							className="Choices-Card"
							choice={choice}
							level={level}
							onSubmit={onUpdate}
							onSelect={onSelect}
							onMove={onMove}
							onDelete={onDelete}
							selected={selectedChoices.includes(choice.id)}
							planConfigs={planConfigs}
						/>
					))}
					{titleForColumn && (
						<ChoiceItem
							className="Choices-Add"
							parentId={parentId}
							onSubmit={onCreate}
							submitLabel="Create"
							planConfigs={planConfigs}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default ChoiceColumn;
