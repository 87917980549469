import RunDetailsToolIcon from '../../assets/images/RunDetailsToolIcon.svg';

import './RunDetailsTool.css';

class RunDetailsTool {

	static blockName = 'runDetails';

	static get toolbox() {
		return {
			title: 'RunDetails',
			icon: '<svg width="100%" height="100%" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(0.54595,0,0,0.54595,11.6242,11.6242)"><path d="M34.498,3C32.014,3 30,5.015 30,7.498C30,9.983 32.014,12 34.498,12C36.987,12 39,9.983 39,7.498C39,5.015 36.987,3 34.498,3ZM20,10C19.617,10 19.242,10.11 18.92,10.316L18.918,10.316L18.887,10.322L11.957,15.295C11.364,15.658 11.001,16.305 11,17C11,18.097 11.903,19 13,19C13.471,18.999 13.927,18.832 14.287,18.527L14.289,18.529L20.283,14.219L24.691,15.619L19.758,23.66L19.758,23.664C19.267,24.344 19.001,25.161 19,26C19.002,27.511 19.86,28.897 21.211,29.572L28.756,34.227L27.049,43.01C27.016,43.171 27,43.335 27,43.5C27,44.871 28.129,46 29.5,46C30.697,45.999 31.733,45.139 31.955,43.963L31.957,43.963L33.984,33.289L33.979,33.273C33.992,33.183 33.999,33.092 34,33C33.999,32.438 33.761,31.902 33.346,31.523L33.34,31.508L27.938,26.721L32.607,17.486L32.605,17.482C32.863,17.031 32.999,16.52 33,16C33,14.807 32.288,13.723 31.193,13.248L31.191,13.244L28.686,11.85L20.52,10.066L20.508,10.068C20.342,10.024 20.172,10.001 20,10ZM34.213,18.742L31.973,23.172L33.182,25.838L33.193,25.846C33.513,26.526 34.199,27 35,27L42,27C43.105,27 44,26.105 44,25C44,23.895 43.105,23 42,23L36,23L34.213,18.742ZM17.518,28.414L15.242,32L7.5,32C6.119,32 5,33.119 5,34.5C5,35.881 6.119,37 7.5,37L15.932,37C17.229,37 18.445,36.371 19.195,35.313L21.48,32.09C20.653,31.579 20.056,31.208 20.049,31.203C18.905,30.555 18.032,29.569 17.518,28.414Z" style="fill-rule:nonzero;"/></g></svg>',
		};
	}

	render() {
		const text = document.createElement('span');
		text.innerText = 'Placeholder for run details';
		const image = document.createElement('img');
		image.src = RunDetailsToolIcon;
		const placeholder = document.createElement('div');
		placeholder.classList.add('RunDetailsPlaceholder');
		placeholder.appendChild(image);
		placeholder.appendChild(text);
		const wrapper = document.createElement('div');
		wrapper.classList.add('RunDetailsTool');
		wrapper.appendChild(placeholder);
		return wrapper;
	}

	save(blockContent) {
		return {};
	}

};

export default RunDetailsTool;
