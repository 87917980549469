import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { Input, Select, JsonField } from '../../components/forms';
import RelatedPlanConfigs from '../../components/planconfigs/RelatedPlanConfigs';
import PlanConfigStatistics from '../../components/planconfigs/PlanConfigStatistics';
import DeletePlanConfigModal from './DeletePlanConfigModal';
import { ROUTES } from '../../constants';

const PlanConfigEditSchema = Yup.object().shape({
	sheetId: Yup.string()
		.required('Please enter a sheet ID.')
		.length(44, 'A sheet ID must have exactly 44 characters.'),
	category: Yup.string()
		.max(32, 'Please enter a shorter category.'),
	title: Yup.string()
		.required('Please enter a title.'),
	displayTitle: Yup.string()
		.required('Please enter a display title.')
		.max(64, 'Please enter a shorter display title.'),
	displaySubtitle: Yup.string()
		.required('Please enter a display subtitle.')
		.max(255, 'Please enter a shorter display subtitle.'),
	priceTierId: Yup.string()
		.nullable()
		.required('Please select a price tier.'),
	intensityCategoryId: Yup.string()
		.nullable()
		.required('Please select an intensity category.'),
});

const PlanConfigEdit = ({ match }) => {
	const [, setLoading] = useState(true);
	const [planConfig, setPlanConfig] = useState(null);
	const [priceTiers, setPriceTiers] = useState([]);
	const [intensityCategories, setIntensityCategories] = useState([]);
	const [showPlanCache, setShowPlanCache] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const { planConfigId } = match.params;

	useEffect(() => {
		const loadPlanConfig = async () => {
			const [
				{ data: { data: planConfig } },
				{ data: { data: priceTiers } },
				{ data: { data: intensityCategories }}
			] = await Promise.all([
				axios.get(`/api/v1/admin/planconfig/${planConfigId}`),
				axios.get('/api/v1/admin/pricetier', {
					params: {
						page: 0,
						size: 999,
					},
				}),
				axios.get(`/api/v1/admin/intensity/category`, {
					params: {
						page: 0,
						size: 999,
					},
				}),
			]);
			setPlanConfig(planConfig);
			setPriceTiers(priceTiers);
			setIntensityCategories(intensityCategories);
			setLoading(false);
		}
		loadPlanConfig();
	}, [planConfigId]);

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			const { data: { data: planConfig } } = await axios.put(`/api/v1/admin/planconfig/${planConfigId}`, {
				sheetId: values.sheetId,
				category: values.category,
				title: values.title,
				displayTitle: values.displayTitle,
				displaySubtitle: values.displaySubtitle,
				priceTierId: values.priceTierId,
				intensityCategoryId: values.intensityCategoryId,
			});
			setPlanConfig(planConfig);
			toast.success('Plan configuration ' + planConfig.id + ' successfully updated!');
		} finally {
			setSubmitting(false);
		}
	};

	const confirmDelete = async () => {
		try {
			const { data: { data: result } } = await axios.post(`/api/v1/admin/planconfig/${planConfigId}:delete`);
			setPlanConfig(result);
			toast.success('Plan configuration ' + planConfig.id + ' successfully deleted!');
		} catch (error) {
			let message = 'An unknown error occurred, please try again!';
			if (error.response?.data?.error?.errorCode === 'ChoicesExist') {
				message = 'A choice using this plan still exists. Please remove the choice first!';
			}
			toast.error(message);
		} finally {
			setShowDeleteModal(false);
		}
	}

	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Edit Plan Config {planConfigId}</h1>
					<div className="mt-3 flex sm:mt-0 sm:ml-4">
						<button
							disabled={planConfig?.status === 'Deleted'}
							onClick={() => setShowDeleteModal(true)}
							className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
						>
							Delete
						</button>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-3 gap-x-4 px-4 py-4 sm:px-6 md:px-8">
				{/* Left part, details */}
				<div className="col-span-2">
					<div className="pb-4">
						<h2 className="text-xl font-semibold text-gray-900">Details</h2>
					</div>
					<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
						{planConfig && (
							<Formik
								initialValues={planConfig}
								validationSchema={PlanConfigEditSchema}
								validateOnBlur={false}
								onSubmit={handleSubmit}
								enableReinitialize
							>
								{({ isSubmitting }) => (
									<Form disabled={isSubmitting}>
										<div className="divide-y divide-gray-200">
											<div>
												<div className="grid grid-cols-2 gap-x-4 pb-4">
													<Input id="id" label="ID" name="id" disabled />
													<Input id="creationDateTime" label="Created" name="creationDateTime" disabled />
												</div>
												<Input className="pb-4" id="status" label="Status" name="status" disabled />
												<Input className="pb-4" id="category" label={<span>Category<sup>*</sup></span>} name="category" disabled={isSubmitting} />
												<Input className="pb-4" id="title" label={<span>Title<sup>*</sup></span>} name="title" disabled={isSubmitting} />
												<Input className="pb-4" id="displayTitle" label={<span>Display Title<sup>*</sup></span>} name="displayTitle" disabled={isSubmitting} />
												<Input className="pb-4" id="displaySubtitle" label={<span>Display Subtitle<sup>*</sup></span>} name="displaySubtitle" disabled={isSubmitting} />
												<Input className="pb-4" id="sheetId" label={<span>Sheet ID<sup>*</sup></span>} name="sheetId" disabled={isSubmitting} />
												<Select className="pb-4" id="priceTierId" label={<span>Price Tier<sup>*</sup></span>} name="priceTierId" disabled={isSubmitting}>
													<option>Please select...</option>
													{priceTiers.map(priceTier => (
														<option key={priceTier.id} value={priceTier.id}>{priceTier.description}</option>
													))}
												</Select>
												<Select className="pb-4" id="intensityCategoryId" label={<span>Intensity Category<sup>*</sup></span>} name="intensityCategoryId" disabled={isSubmitting}>
													<option>Please select...</option>
													{intensityCategories.map((intensityCategory) => (
														<option key={intensityCategory.id} value={intensityCategory.id}>{intensityCategory.title}</option>
													))}
												</Select>
											</div>
											<div className="bt-1 pt-8">
												<div className="flex justify-end">
													<Link
														as="button"
														to={ROUTES.PLAN_CONFIG_LIST}
														className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
													>
														Cancel
													</Link>
													<button
														type="submit"
														className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
													>
														Update
													</button>
												</div>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						)}
					</div>
				</div>

				{/* Right part, related plans and statistics */}
				<div>
					<div className="pb-4">
						<h2 className="text-xl font-semibold text-gray-900">Statistics</h2>
					</div>
					<div className="pg-white shadow sm:rounded-lg overflow-hidden mb-8">
						{planConfig && <PlanConfigStatistics planConfig={planConfig} />}
					</div>

					<div className="pb-4">
						<h2 className="text-xl font-semibold text-gray-900">Related Plans</h2>
					</div>
					<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
						{planConfig && <RelatedPlanConfigs planConfig={planConfig} />}
					</div>
				</div>
			</div>

			{/* Plan Cache */}
			<div className="px-4 py-4 sm:px-6 md:px-8">
				<div className="mx-auto pt-8 pb-4">
					<h2 className="text-xl font-semibold text-gray-900">Plan Cache</h2>
				</div>
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					{planConfig && (
						<Formik initialValues={planConfig}>
							<Form>
								<div className="grid grid-cols-2 gap-x-4 pb-4">
									<Input id="planCache.status" label="Status" name="planCache.status" disabled />
									<Input id="planCache.timestamp" label="Updated" name="planCache.timestamp" disabled />
								</div>
								{!showPlanCache && (
									<p>
										<button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500" onClick={() => setShowPlanCache(true)}>Click here to show the plan cache (takes some time).</button>
									</p>
								)}
								{showPlanCache && (
									<>
										<p><button type="button" className="mb-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500" onClick={() => setShowPlanCache(false)}>Click here to hide the plan cache.</button></p>
										<JsonField id="planCache" label="JSON View" name="planCache" disabled />
									</>
								)}

							</Form>
						</Formik>
					)}
				</div>
			</div>

			<DeletePlanConfigModal
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				onConfirmDelete={confirmDelete}
			/>

		</>
	);
};

export default PlanConfigEdit;
