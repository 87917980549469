import React from 'react';
import { ErrorMessage, useField } from 'formik';

const Select = ({ className, label, children, ...props }) => {
	const [field, meta] = useField(props);
	const { id, name, hideLabel } = props;
	const { touched, error } = meta;

	return (
		<div className={className}>
			{!hideLabel && (
				<label htmlFor={id} className="block text-sm font-medium text-gray-700">
					{label}
				</label>
			)}
			<div className="mt-1 relative rounded-md shadow-sm">
				<select
					name={name}
					id={id}
					{...field}
					{...props}
					className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
				>
					{children}
				</select>
			</div>

			{touched && error && (
				<p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
					<ErrorMessage name={name} />
				</p>
			)}
		</div>
	)
};

export default Select;
