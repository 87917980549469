import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { RichEditorField, Input, Select, RunDetailsTool } from '../../components/forms';
import { ROUTES } from '../../constants';

const ExerciseEditSchema = Yup.object().shape({
	content: Yup.object()
		.nullable()
		.required('Please enter a text.'),
	type: Yup.string()
		.required('Please select a type.'),
	identifier: Yup.string()
		.required('Please enter an identifier.')
		.max(32, 'Please enter a shorter identifier.'),
	title: Yup.string()
		.required('Please enter a title.')
		.max(255, 'Please enter a shorter title.'),
	duration: Yup.number()
		.required('Please enter a duration.')
		.positive('Please enter a positive number.')
		.integer('Please enter an integer.'),
});

const ExerciseEdit = ({ match }) => {
	const [,setLoading] = useState(true);
	const [exercise, setExercise] = useState(null);
	const { exerciseId } = match.params;

	useEffect(() => {
		const loadExercise = async () => {
			const { data: { data: exercise } } = await axios.get(`/api/v1/admin/exercise/${exerciseId}`);
			setExercise(exercise);
			setLoading(false);
		}
		loadExercise();
	}, [exerciseId]);

	const updateExercise = async (values, { setSubmitting, setFieldError }) => {
		// Validate the blocks
		const countOfRunDetails = values.content.blocks?.filter((block) => block.type === RunDetailsTool.blockName).length;
		if (values.type !== 'Run' && countOfRunDetails > 0) {
			console.log('Block of type RunDetails found in exercise which is not type Run!');
			setFieldError('content', 'A RunDetails block can only be used in an exercise of type Run!');
			setSubmitting(false);
			return;
		}
		if (countOfRunDetails > 1) {
			console.log('Too many blocks of type RunDetails!');
			setFieldError('content', 'You cannot have more than one RunDetails block in your exercise content!');
			toast.error('An unknown error occurred!');
			setSubmitting(false);
			return;
		}
		// Submit
		try {
			const { data: { data: exercise } } = await axios.put(`/api/v1/admin/exercise/${exerciseId}`, {
				content: values.content,
				type: values.type,
				identifier: values.identifier,
				title: values.title,
				duration: values.duration,
			});
			setExercise(exercise);
			toast.success('Exercise ' + exercise.id + ' successfully updated!');
		} catch (error) {
			if (error?.response?.data?.error?.errorCode === 'DuplicateField') {
				toast.error('The identifier "' + values.identifier + '" is already in use!');
				setFieldError('identifier', 'This identifier is already in use!');
			} else {
				toast.error('An unknown error occurred!');
			}
		} finally {
			setSubmitting(false);
		}
	};

	const handleMediaUpload = async (file) => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('request', new Blob([JSON.stringify({
			ownerType: 'Exercise',
			ownerId: exercise.id,
			mediaType: 'Exercise',
		})], {
			type: "application/json"
		}));
		const { data: { data: media } } = await axios.post('/api/v1/admin/media', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return {
			success: 1,
			mediaId: media.id,
			width: media.width,
			height: media.height,
		};
	};

	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Edit Exercise {exerciseId}</h1>
				</div>
			</div>
			{exercise && (
				<Formik
					initialValues={exercise}
					validationSchema={ExerciseEditSchema}
					validateOnBlur={false}
					onSubmit={updateExercise}
				>
					{({ isSubmitting }) => (
						<Form disabled={isSubmitting}>
							<div className="px-4 py-4 sm:px-6 md:px-8">
								<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
									<div className="divide-y divide-gray-200">
										<div className="grid grid-cols-3 gap-x-8">
											<RichEditorField
												className="col-span-2 mb-4"
												label="Content"
												id="content"
												name="content"
												disabled={isSubmitting}
												onMediaUpload={handleMediaUpload}
												autofocus
												placeholder="Describe the exercise..."
											/>

											<div>
												<Input className="pb-4" id="id" label="ID" name="id" disabled={true} />
												{/* <DatePicker id="creationDateTime" label="Created" name="creationDateTime" disabled={true} /> */}
												<Select className="pb-4" id="type" label={<span>Type<sup>*</sup></span>} name="type" disabled={isSubmitting}>
													<option>Please select...</option>
													<option value="WarmUp">Warm Up</option>
													<option value="Mobilization">Mobilization</option>
													<option value="Power">Power</option>
													<option value="Coordination">Coordination</option>
													<option value="Intro">Intro</option>
													<option value="Run">Run</option>
													<option value="WarmDown">Warm Down</option>
													<option value="FasciaTraining">Fascia Training</option>
												</Select>
												<Input className="pb-4" id="identifier" label={<span>Identifier<sup>*</sup></span>} name="identifier" disabled={isSubmitting} />
												<Input className="pb-4" id="title" label={<span>Title<sup>*</sup></span>} name="title" disabled={isSubmitting} />
												<Input className="pb-4" id="duration" label={<span>Duration (seconds)<sup>*</sup></span>} name="duration" disabled={isSubmitting} />
											</div>
										</div>

										<div className="bt-1 pt-8">
											<div className="flex justify-end">
												<Link
													as="button"
													to={ROUTES.EXERCISE_LIST}
													className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Cancel
												</Link>
												<button
													type="submit"
													className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Save
												</button>
											</div>
										</div>

									</div>

								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default ExerciseEdit;
