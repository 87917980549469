import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../components/forms';

const LoginSchema = Yup.object().shape({
	email: Yup.string()
		.required('Please enter your email address.')
		.max(255, 'Please enter a shorter email address.')
		.email('Please enter a valid email address.'),
	password: Yup.string()
		.required('Please enter your password.')
		.min(6, 'Please use at least 6 characters.')
});

const Login = ({ onLogin }) => {
	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			await onLogin(values.email, values.password);
		} catch {
			setSubmitting(false);
		}
	};

	return (
		<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img
					className="mx-auto h-20 w-auto"
					src={require('../../assets/images/HiRunner_Logo_Full_RGB.svg').default}
					alt="HiRunner"
				/>
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Login</h2>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white p-4 shadow sm:rounded-lg">
					<Formik
						initialValues={{
							email: '',
							password: ''
						}}
						validationSchema={LoginSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form className="space-y-6" disabled={isSubmitting}>
								<Input label="E-Mail" id="email" name="email" type="email" />
								<Input label="Password" id="password" name="password" type="password" />
								<div>
									<button
										type="submit"
										className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
									>
										Sign in
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);

};

export default Login;
