import React from 'react';
import { Transition } from '@headlessui/react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Toaster, ToastIcon, resolveValue } from 'react-hot-toast';

import useAuth, { AuthProvider } from './hooks/useAuth';
import { AppWrapper } from './components/application';
import { ROUTES } from './constants';
import {
	Login,
	Dashboard,
	PriceTierList,
	PriceTierCreate,
	PriceTierEdit,
	IntensityCategoryList,
	IntensityCategoryCreate,
	IntensityCategoryEdit,
	PlanConfigList,
	PlanConfigCreate,
	PlanConfigEdit,
	ExerciseList,
	ExerciseCreate,
	ExerciseEdit,
	Choices,
	MediaEdit,
	MediaList,
	RunnerList,
	RunnerEdit,
	PlanList,
	PlanEdit,
	PurchaseList,
	ExerciseFeedbackList,
	ExerciseFeedbackEdit,
	EventList,
} from './routes';

const InnerApp = () => {
	const { user, loading, login, logout } = useAuth();

	if (loading) {
		return (
			<div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
				<div className="max-w-max mx-auto">
				<h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Loading...</h1>
				</div>
			</div>
		);
	}

	if (!user) {
		return (
			<Switch>
				<Route path={ROUTES.LOGIN} component={() => <Login onLogin={login} />} />
				<Route render={() => <Redirect to={ROUTES.LOGIN} />} />
			</Switch>
		);
	}

	return (
		<AppWrapper onLogout={logout}>
			<Switch>
				<Route path={ROUTES.DASHBOARD} component={Dashboard} />
				<Route path={ROUTES.PRICE_TIER_CREATE} component={PriceTierCreate} />
				<Route path={ROUTES.PRICE_TIER_EDIT} component={PriceTierEdit} />
				<Route path={ROUTES.PRICE_TIER_LIST} component={PriceTierList} />
				<Route path={ROUTES.INTENSITY_CATEGORY_CREATE} component={IntensityCategoryCreate} />
				<Route path={ROUTES.INTENSITY_CATEGORY_EDIT} component={IntensityCategoryEdit} />
				<Route path={ROUTES.INTENSITY_CATEGORY_LIST} component={IntensityCategoryList} />
				<Route path={ROUTES.PLAN_CONFIG_CREATE} component={PlanConfigCreate} />
				<Route path={ROUTES.PLAN_CONFIG_EDIT} component={PlanConfigEdit} />
				<Route path={ROUTES.PLAN_CONFIG_LIST} component={PlanConfigList} />
				<Route path={ROUTES.EXERCISE_CREATE} component={ExerciseCreate} />
				<Route path={ROUTES.EXERCISE_EDIT} component={ExerciseEdit} />
				<Route path={ROUTES.EXERCISE_LIST} component={ExerciseList} />
				<Route path={ROUTES.CHOICES} component={Choices} />
				<Route path={ROUTES.MEDIA_EDIT} component={MediaEdit} />
				<Route path={ROUTES.MEDIA_LIST} component={MediaList} />
				<Route path={ROUTES.RUNNER_EDIT} component={RunnerEdit} />
				<Route path={ROUTES.RUNNER_LIST} component={RunnerList} />
				<Route path={ROUTES.PLAN_EDIT} component={PlanEdit} />
				<Route path={ROUTES.PLAN_LIST} component={PlanList} />
				<Route path={ROUTES.PURCHASE_LIST} component={PurchaseList} />
				<Route path={ROUTES.EXERCISE_FEEDBACK_EDIT} component={ExerciseFeedbackEdit} />
				<Route path={ROUTES.EXERCISE_FEEDBACK_LIST} component={ExerciseFeedbackList} />
				<Route path={ROUTES.EVENT_LIST} component={EventList} />
			</Switch>
			<Toaster
				toastOptions={{
					duration: 5000,
				}}
			>
				{(t) => (
					<Transition
						appear
						show={t.visible}
						className="transform p-4 flex bg-white rounded shadow-lg"
						enter="transition-all duration-150"
						enterFrom="opacity-0 scale-50"
						enterTo="opacity-100 scale-100"
						leave="transition-all duration-150"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-75"
					>
						<ToastIcon toast={t} />
						<p className="px-2">{resolveValue(t.message)}</p>
					</Transition>
				)}
			</Toaster>
		</AppWrapper>
	);
}

const OuterApp = () => {
	return (
		<Router>
			<AuthProvider>
				<InnerApp />
			</AuthProvider>
		</Router>
	);
};

export default OuterApp;
