import React, { useEffect, useState } from 'react';

import { PlanEditForm } from '../plans';

const ActivePlans = ({ activePlans }) => {
	const [selectedPlan, setSelectedPlan] = useState();

	useEffect(() => {
		if (activePlans && activePlans.length > 0) {
			setSelectedPlan(activePlans[0]);
		}
	}, [activePlans]);

	if (!activePlans || activePlans.length === 0) {
		// TODO empty slate
		return null;
	}

	return (
		<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
			<label htmlFor="active-plan" className="block text-sm font-medium text-gray-700">
				Active Plan(s)
			</label>
			<div className="mt-1 relative rounded-md shadow-sm">
				<select
					name="active-plan"
					id="active-plan"
					className="mb-4 mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
					value={selectedPlan?.id}
				>
					{activePlans.map((activePlan) => (
						<option key={activePlan.id} value={activePlan.id}>{activePlan.details.title} ({activePlan.status})</option>
					))}
				</select>
			</div>

			{selectedPlan && <PlanEditForm plan={selectedPlan} />}

		</div>
	);
};

export default ActivePlans;
