import React from 'react';

import { RunnerRegistrationsPerDayCard, PurchasesPerDayCard, LastActiveRunnersCard, PopularPlanConfigsCard, LastEventsCard, ActivePlansPerDayCard } from '../../components/dashboard';

const Dashboard = () => {
	return (
		<>
			<div className="p-4 masonry-3-col gap-5">
				<RunnerRegistrationsPerDayCard />
				<ActivePlansPerDayCard />
				<PopularPlanConfigsCard />
				<LastActiveRunnersCard />
				<LastEventsCard />
				<PurchasesPerDayCard />
			</div>
		</>
	);
};

export default Dashboard;
