import React from 'react';
import { useHistory, generatePath, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { ROUTES } from '../../constants';
import { Input } from '../../components/forms';

const initialValues = {
	description: '',
	iosSku: '',
	androidSku: '',
};

const PriceTierCreateSchema = Yup.object().shape({
	description: Yup.string()
		.required('Please enter a description.')
		.max(64, 'Please enter a shorter description.'),
	iosSku: Yup.string()
		.when('androidSku', {
			is: androidSku => Boolean(androidSku),
			then: Yup.string().required('Please enter an iOS SKU.').max(64, 'Please enter a shorter iOS SKU.'),
		}),
	androidSku: Yup.string()
		.when('iosSku', {
			is: iosSku => Boolean(iosSku),
			then: Yup.string().required('Please enter an Android SKU.').max(64, 'Please enter a shorter Android SKU.'),
		}),
}, [['iosSku', 'androidSku'], ['androidSku', 'iosSku']]);

const PriceTierCreate = () => {
	const history = useHistory();

	const handleSubmit = async (values, { setSubmitting }) => {
		const data = {
			description: values.description,
		};
		if (values.androidSku || values.iosSku) {
			data.androidSku = values.androidSku;
			data.iosSku = values.iosSku;
		}
		try {
			const { data: { data: priceTier } } = await axios.post('/api/v1/admin/pricetier', data);
			setSubmitting(false);
			history.push(generatePath(ROUTES.PRICE_TIER_EDIT, {
				priceTierId: priceTier.id
			}));
		} catch {
			setSubmitting(false);
		}
	};

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Create Price Tier</h1>
				</div>
			</div>
			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					<Formik
						initialValues={initialValues}
						validationSchema={PriceTierCreateSchema}
						validateOnBlur={false}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form disabled={isSubmitting}>
								<div className="divide-y divide-gray-200">
									<div className="grid gap-x-4 pb-8">
										<Input id="description" label={<span>Description<sup>*</sup></span>} name="description" disabled={isSubmitting} autofocus />
										<p className="pt-8 pb-4 text-sm text-gray-700">
											The SKUs are optional; if none are provided, this price tier is considered to be free and created plans will be fully activated.
											If SKUs are provided here, both fields need to be filled and they MUST match corresponding SKUs configured in AppStore Connect
											or Google Play Console. Otherwise In-App Purchases will fail!
										</p>
										<div className="grid grid-cols-2 gap-x-4">
											<Input id="iosSku" label="iOS SKU" name="iosSku" disabled={isSubmitting} />
											<Input id="androidSku" label="Android SKU" name="androidSku" disabled={isSubmitting} />
										</div>
									</div>
									<div className="bt-1 pt-8">
										<div className="flex justify-end">
											<Link
												as="button"
												to={ROUTES.PRICE_TIER_LIST}
												className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Cancel
											</Link>
											<button
												type="submit"
												className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Save
											</button>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default PriceTierCreate;
