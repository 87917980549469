import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const TextArea = (props) => {
	const [field, meta] = useField(props);
	const { className, id, label, name, rows = 3, autofocus = false, extraComponent, hideLabel = false, required, disabled, readonly, ...rest } = props;
	const { touched, error } = meta;

	return (
		<div className={className}>
			{!hideLabel && (
				<label htmlFor={id} className="block text-sm font-medium text-gray-700">
					{label}
				</label>
			)}
			<div className="mt-1 relative rounded-md shadow-sm">
				<textarea
					id={id}
					name={id}
					rows={rows}
					autoComplete={id}
					required={required}
					autoFocus={autofocus}
					disabled={disabled}
					readOnly={readonly}
					className={classNames(
						'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm sm:text-sm',
						touched && error ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500',
						(disabled || readonly) && 'opacity-50',
					)}
					aria-invalid={Boolean(touched && error)}
					aria-describedby={`${id}-error`}
					{...field}
					{...rest}
				/>
				{touched && error && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
				)}
			</div>

			{touched && error && (
				<p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
					<ErrorMessage name={name} />
				</p>
			)}

			{extraComponent}
		</div>
	);
};

export default TextArea;
