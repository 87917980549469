const routes = {
	LOGIN: '/login',
	DASHBOARD: '/secure/dashboard',
	PRICE_TIER_LIST: '/secure/pricetiers',
	PRICE_TIER_CREATE: '/secure/pricetiers/create',
	PRICE_TIER_EDIT: '/secure/pricetiers/:priceTierId',
	INTENSITY_CATEGORY_LIST: '/secure/intensitycategories',
	INTENSITY_CATEGORY_CREATE: '/secure/intensitycategories/create',
	INTENSITY_CATEGORY_EDIT: '/secure/intensitycategories/:intensityCategoryId',
	PLAN_CONFIG_LIST: '/secure/planconfigs',
	PLAN_CONFIG_CREATE: '/secure/planconfigs/create',
	PLAN_CONFIG_EDIT: '/secure/planconfigs/:planConfigId',
	EXERCISE_LIST: '/secure/exercises',
	EXERCISE_CREATE: '/secure/exercises/create',
	EXERCISE_EDIT: '/secure/exercises/:exerciseId',
	CHOICES: '/secure/choices',
	MEDIA_LIST: '/secure/media',
	MEDIA_EDIT: '/secure/media/:mediaId',
	RUNNER_LIST: '/secure/runners',
	RUNNER_EDIT: '/secure/runners/:runnerId',
	PLAN_LIST: '/secure/plans',
	PLAN_EDIT: '/secure/plans/:planId',
	PURCHASE_LIST: '/secure/purchases',
	PURCHASE_EDIT: '/secure/purchases/:purchaseId',
	EXERCISE_FEEDBACK_LIST: '/secure/exercise-feedback',
	EXERCISE_FEEDBACK_EDIT: '/secure/exercise-feedback/:exerciseFeedbackId',
	EVENT_LIST: '/secure/events',
	ADMIN_LIST: '/secure/admins',
	ADMIN_EDIT: '/secure/admins/:adminId',
};

export default routes;
