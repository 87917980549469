import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../components/forms';

const IntensityCategoryItemUpdateSchema = Yup.object().shape({
	label: Yup.string()
		.max(64, 'Please enter a shorter label.')
		.test(
			'labelExcl',
			'Please enter either a label or a pace.',
			function(value) {
				if (this.parent.pace && value) {
					return false;
				} else {
					return true;
				}
			}
		),
	pace: Yup.string()
		.max(10, 'Please enter a shorter pace.')
		.test(
			'paceExcl',
			'Please enter either a label or a pace.',
			function(value) {
				if (this.parent.label && value) {
					return false;
				} else {
					return true;
				}
			},
		),
});

const EditIntensityCategoryItemModal = ({ show, intensityCategoryItem, onHide, onSubmit }) => {
	const cancelButtonRef = useRef();
	const formikRef = useRef();
	const initialValues = {
		intensity: intensityCategoryItem?.intensity,
		label: intensityCategoryItem?.label || '',
		pace: intensityCategoryItem?.pace ? ~~(intensityCategoryItem.pace / 60) + ':' + ('0' + intensityCategoryItem.pace % 60).slice(-2) : '',
	};
	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-10 inset-0 overflow-y-auto"
				open={show}
				onClose={onHide}
				initialFocus={cancelButtonRef}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
							<Formik
								initialValues={initialValues}
								validationSchema={IntensityCategoryItemUpdateSchema}
								validateOnBlur={false}
								onSubmit={onSubmit}
								innerRef={formikRef}
							>
								{({ isSubmitting }) => (
									<Form disabled={isSubmitting}>
										<div className="p-4">
											<Input className="pb-4" id="intensity" label="Intensity" name="intensity" disabled />
											<Input className="pb-4" id="label" label="Label" name="label" disabled={isSubmitting} />
											<Input id="pace" label="Pace" name="pace" placeholder="e.g. 6:30" disabled={isSubmitting} />
										</div>
										<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
											<button
												type="submit"
												className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
											>
												Update
											</button>
											<button
												type="button"
												className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
												onClick={onHide}
												ref={cancelButtonRef}
											>
												Cancel
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default EditIntensityCategoryItemModal;
