import React, { useState, useEffect } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import axios from '../../api/axios';
import { Table } from '../../components/table';
import { ROUTES } from '../../constants';

const IntensityCategoryCell = ({ intensityCategoryId }) => {
	const [intensityCategory, setIntensityCategory] = useState();
	useEffect(() => {
		const loadIntensityCategoryAsync = async () => {
			const { data: { data: loadedIntensityCategory } } = await axios.get(`/api/v1/admin/intensity/category/${intensityCategoryId}`);
			setIntensityCategory(loadedIntensityCategory);
		};
		loadIntensityCategoryAsync();
	}, [intensityCategoryId]);

	if (!intensityCategory) {
		return <span>Loading...</span>;
	} else {
		return <Link className="underline" to={generatePath(ROUTES.INTENSITY_CATEGORY_EDIT, { intensityCategoryId })}>{intensityCategory.title}</Link>;
	}
};

const columns = [
	{
		field: 'title',
		label: 'Title',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'category',
		label: 'Category',
	},
	{
		field: 'intensityCategoryId',
		label: 'Intensity',
		cell: (intensityCategoryId) => <IntensityCategoryCell intensityCategoryId={intensityCategoryId} />,
	},
	{
		field: 'sheetId',
		label: 'Sheet ID',
		cell: (sheetId) => <a href={`https://docs.google.com/spreadsheets/d/${sheetId}`} target="_new" className="font-medium rounded-md text-cyan-600 hover:text-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">{sheetId}</a>,
	},
	{
		field: 'status',
		label: 'Status',
	},
];

const filters = [
	{
		field: 'title',
		type: 'text',
	},
	{
		field: 'category',
		type: 'text',
	},
	{
		field: 'status',
		type: 'select',
		values: [
			{ value: '', label: '(please select)' },
			{ value: 'Active', label: 'Active' },
			{ value: 'Deleted', label: 'Deleted' },
		],
	},
];

const initialFilters = [
	{ field: 'status', value: 'Active' },
];

const PlanConfigList = () => {
	const history = useHistory();
	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Plan Configs</h1>
					<div className="mt-3 flex sm:mt-0 sm:ml-4">
						<Link
							to={ROUTES.PLAN_CONFIG_CREATE}
							as="button"
							className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
						>
							Create Plan Configuration
						</Link>
					</div>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="py-4">
					<Table
						url="/api/v1/admin/planconfig"
						columns={columns}
						filters={filters}
						initialFilters={initialFilters}
						onEditClick={(row) => history.push(generatePath(ROUTES.PLAN_CONFIG_EDIT, { planConfigId: row.id }))}
					/>
				</div>
			</div>
		</>
	);
};

export default PlanConfigList;
