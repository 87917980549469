import React from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { Table } from '../../components/table';
import { ROUTES } from '../../constants';

const columns = [
	{
		field: 'identifier',
		label: 'Identifier',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'title',
		label: 'Title',
	},
	{
		field: 'type',
		label: 'Type',
	},
];

const filters = [
	{
		field: 'identifier',
		type: 'text',
	},
	{
		field: 'title',
		type: 'text',
	},
	{
		field: 'type',
		type: 'select',
		values: [
			{ value: '', label: '(please select)' },
			{ value: 'WarmUp', label: 'WarmUp' },
			{ value: 'Mobilization', label: 'Mobilization' },
			{ value: 'Power', label: 'Power' },
			{ value: 'Coordination', label: 'Coordination' },
			{ value: 'Run', label: 'Run' },
			{ value: 'WarmDown', label: 'WarmDown' },
			{ value: 'FasciaTraining', label: 'FasciaTraining' },
			{ value: 'Intro', label: 'Intro' },
		],
	},
];

const ExerciseList = () => {
	const history = useHistory();
	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Exercises</h1>
					<div className="mt-3 flex sm:mt-0 sm:ml-4">
						<Link
							to={ROUTES.EXERCISE_CREATE}
							as="button"
							className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
						>
							Create Exercise
						</Link>
					</div>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="py-4">
					<Table
						url="/api/v1/admin/exercise"
						columns={columns}
						filters={filters}
						onEditClick={(row) => history.push(generatePath(ROUTES.EXERCISE_EDIT, { exerciseId: row.id }))}
					/>
				</div>
			</div>
		</>
	);
};

export default ExerciseList;
