import React, { useState, useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';

import axios from '../../api/axios';
import { Input, Select, TextArea } from '../../components/forms';
import { ROUTES } from '../../constants';
import useAuth from '../../hooks/useAuth';

const ExerciseFeedbackEditSchema = Yup.object().shape({
	response: Yup.string(),
});

const ExerciseFeedbackEdit = ({ match }) => {
	const [exerciseFeedback, setExerciseFeedback] = useState();
	const [admins, setAdmins] = useState([]);
	const { exerciseFeedbackId } = match.params;
	const { user } = useAuth();

	useEffect(() => {
		const loadDataAsync = async () => {
			const [
				{ data: { data: loadedExerciseFeedback } },
				{ data: { data: loadedAdmins } },
			] = await Promise.all([
				 axios.get(`/api/v1/admin/exercise/feedback/${exerciseFeedbackId}`),
				 axios.get(`/api/v1/admin/admins`),
			]);
			setExerciseFeedback(loadedExerciseFeedback);
			setAdmins(loadedAdmins);
		}
		loadDataAsync();
	}, [exerciseFeedbackId]);

	const handleSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			const { data: { data: updatedExerciseFeedback } } = await axios.put(`/api/v1/admin/exercise/feedback/${exerciseFeedbackId}`, {
				status: values.status,
				response: values.response,
				assignedTo: values.assignedToId ? {
					type: 'Admin',
					id: values.assignedToId,
				} : null,
			});
			setExerciseFeedback(updatedExerciseFeedback);
			toast.success('Exercise feedback successfully updated.');
		} catch {
			toast.error('An error occurred, please try again.');
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Edit Exercise Feedback {exerciseFeedbackId}</h1>
				</div>
			</div>
			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					{exerciseFeedback && (
						<Formik
							initialValues={{
								...exerciseFeedback,
								assignedToId: exerciseFeedback.assignedTo ? exerciseFeedback.assignedTo.id : '',
								comment: exerciseFeedback.comment || '',
								response: exerciseFeedback.response || '',
							}}
							validationSchema={ExerciseFeedbackEditSchema}
							validateOnBlur={false}
							onSubmit={handleSubmit}
						>
							{({ isSubmitting, setFieldValue, values }) => (
								<Form disabled={isSubmitting}>
									<div className="divide-y divide-gray-200">
										<div>
											<div className="grid grid-cols-2 gap-x-4 pb-4">
												<Input id="id" label="ID" name="id" disabled />
												<Input id="creationDateTime" label="Created" name="creationDateTime" disabled />
											</div>
											<div className="grid grid-cols-3 gap-x-4 pb-4">
												<Input id="value" label="Value" name="value" disabled />
												<div>
													<label className="block text-sm font-medium text-gray-700">
														Author
													</label>
													<div className="mt-1 block w-full py-2 sm:text-sm">
														<Link className="underline" to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: values.author.id })}>Runner {values.author.id}</Link>
													</div>
												</div>
												<div>
													<label className="block text-sm font-medium text-gray-700">
														Context
													</label>
													<div className="mt-1 block w-full py-2 sm:text-sm">
														<Link className="underline" to={generatePath(ROUTES.PLAN_EDIT, { planId: values.context.id })}>Plan {values.context.id}</Link>
													</div>
												</div>
											</div>
											<Input className="pb-4" id="identifier" label="Identifier" name="identifier" disabled />
											<TextArea className="pb-4" id="comment" label="Comment" name="comment" disabled />

											<Select className="pb-4" id="status" label="Status" name="status">
												<option value="New">New</option>
												<option value="Assigned">Assigned</option>
												<option value="Closed">Closed</option>
											</Select>
											<div className="align-middle inline-block">
												<Select className="inline-block pb-4 mr-2 grow" id="assignedToId" label="Assigned to" name="assignedToId">
													<option value="">(Please select...)</option>
													{admins.map((admin) => <option key={admin.id} value={admin.id}>{admin.email}</option>)}
												</Select>
												<button
													type="button"
													className="inline-flex items-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
													onClick={() => setFieldValue('assignedToId', user.id)}
												>
													Assign to me
												</button>
											</div>

											<TextArea className="pb-4" id="response" label="Internal Notes" name="response" />
										</div>
										<div className="bt-1 pt-8">
											<div className="flex justify-end">
												<Link
													as="button"
													to={ROUTES.EXERCISE_FEEDBACK_LIST}
													className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Cancel
												</Link>
												<button
													type="submit"
													className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Save
												</button>
											</div>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					)}
				</div>
			</div>
		</div>
	);
};

export default ExerciseFeedbackEdit;
