import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Input } from '../forms';

const TitleSchema = Yup.object().shape({
	title: Yup.string()
		.required('Please select a title.')
		.max(64, 'Please enter a shorter title.'),
	subtitle: Yup.string()
		.matches(/https:\/\/(www.)?hirunner\.co\/blog\/([-a-zA-Z0-9#/]*)$/, 'URL must start with https://hirunner.co/blog/!')
		.max(255, 'Please enter a shorter URL.'),
});

const TitleItem = ({ parentId, className, onSubmit, title }) => {
	const initialValues = {
		title: '',
		subtitle: '',
		parentId,
		...title,
		type: 'Title',
	};
	return (
		<div className="bg-white shadow-sm rounded-md p-4 mb-4 m-2">
			<Formik
				initialValues={initialValues}
				validationSchema={TitleSchema}
				validateOnBlur={false}
				onSubmit={onSubmit}
				enableReinitialize
			>
				{({ isSubmitting }) => (
					<Form name="TitleForm" disabled={isSubmitting}>
						<Input className="pb-4" id="title" label={<span>Title<sup>*</sup></span>} name="title" disabled={isSubmitting} />
						<Input className="pb-4" id="subtitle" label="Help URL" name="subtitle" placeholder="use 'https://hirunner.co/blog/mobile-app/...'" disabled={isSubmitting} />
						<button
							type="submit"
							className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
							disabled={isSubmitting}
						>
							{isSubmitting ? 'Please wait...' : (title ? 'Update' : 'Create')}
						</button>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default TitleItem;
