import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import Typeahead from '../forms/Typeahead';

const RelatedPlanConfigUpdateSchema = Yup.object().shape({
	id: Yup.string()
		.nullable()
		.required('Please select a related plan config.'),
});

const RelatedPlanConfig = ({ className, planConfig, otherPlanConfigs, onSubmit }) => (
	<div className={className}>
		<Formik
			initialValues={planConfig}
			validationSchema={RelatedPlanConfigUpdateSchema}
			validateOnBlur={false}
			onSubmit={onSubmit}
		>
			{({ isSubmitting }) => (
				<Form disabled={isSubmitting}>
					<div className="flex justify-content-between">
						<Typeahead
							className="flex-grow"
							id="id"
							name="id"
							hideLabel
							disabled={isSubmitting || planConfig.id}
							labelKey="title"
							options={planConfig.id ? [...otherPlanConfigs, planConfig] : otherPlanConfigs}
							selectionToValue={(selection) => selection?.id}
							valueToSelection={(value) => value === planConfig.id && planConfig.title ? planConfig : otherPlanConfigs.find((planConfig) => planConfig.id === value)}
							clearButton
						/>
						<div className="ml-3 flex-shrink">
							<button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
								{planConfig.id ? 'Delete' : 'Save'}
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	</div>
);

const RelatedPlanConfigs = ({ planConfig }) => {
	//const { addToast } = useToasts();
	const [newRelatedPlanConfig, setNewRelatedPlanConfig] = useState({
		id: '',
		title: '',
	});
	const [otherPlanConfigs, setOtherPlanConfigs] = useState([]);
	const [relatedPlanConfigs, setRelatedPlanConfigs] = useState([]);

	useEffect(() => {
		const loadDataAsync = async () => {
			const [
				{ data: { data: otherPlanConfigs } },
				{ data: { data: relatedPlanConfigs } }
			 ] = await Promise.all([
				 axios.request('/api/v1/admin/planconfig', {
					 params: {
						 page: 0,
						 size: 500,
						 status: 'Active',
					 },
				 }),
				 axios.request(`/api/v1/admin/planconfig/${planConfig.id}/related`),
			]);
			// Other available plan configs are those which are not the presented plan config
			// and not yet configured as a related plan config
			setOtherPlanConfigs(otherPlanConfigs.filter(
				(otherPlanConfig) => otherPlanConfig.id !== planConfig.id && !relatedPlanConfigs.find((relatedPlanConfig) => relatedPlanConfig.id === otherPlanConfig.id)
			));
			setRelatedPlanConfigs(relatedPlanConfigs);
		};
		loadDataAsync();
	}, [planConfig.id]);

	const deleteRelatedPlanConfig = async (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			// Delete
			await axios.delete(`/api/v1/admin/planconfig/${planConfig.id}/related`, {
				data: {
					relatedPlanConfigId: values.id,
				},
			});
			// Remove the deleted plan config from the list and add it to the available options:
			setRelatedPlanConfigs(relatedPlanConfigs.filter((relatedPlanConfig) => relatedPlanConfig.id !== values.id));
			setOtherPlanConfigs([...otherPlanConfigs, values]);
			// Success
			//addToast('Related plan configuration successfully deleted.', { appearance: 'success' });
		} catch (_) {
			//addToast('An error occurred. Please reload the page and retry.', { appearance: 'error' });
		} finally {
			setSubmitting(false);
		}
	};

	const createRelatedPlanConfig = async (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			// Create
			await axios.post(`/api/v1/admin/planconfig/${planConfig.id}/related`, {
				relatedPlanConfigId: values.id,
			});
			// Add the new related plan config to the list and remove it from the available options:
			setRelatedPlanConfigs([...relatedPlanConfigs, otherPlanConfigs.find((otherPlanConfig) => otherPlanConfig.id === values.id)]);
			setOtherPlanConfigs(otherPlanConfigs.filter((otherPlanConfig) => otherPlanConfig.id !== values.id));
			// Clear out the new row
			setNewRelatedPlanConfig({
				id: '',
				title: '',
			});
			// Success
			//addToast('Related plan configuration successfully saved.', { appearance: 'success' });
		} catch (_) {
			//addToast('An error occurred. Please reload the page and retry.', { appearance: 'error' });
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<>
			{relatedPlanConfigs.map((relatedPlanConfig) => (
				<RelatedPlanConfig
					key={relatedPlanConfig.id}
					className="mb-4"
					planConfig={relatedPlanConfig}
					otherPlanConfigs={otherPlanConfigs}
					onSubmit={deleteRelatedPlanConfig}
				/>
			))}
			{otherPlanConfigs.length > 0 ?
				<RelatedPlanConfig
					planConfig={newRelatedPlanConfig}
					otherPlanConfigs={otherPlanConfigs}
					onSubmit={createRelatedPlanConfig}
				/>
			:
				<p>There are no other plan configurations available to select.</p>
			}
		</>
	);
};

export default RelatedPlanConfigs;
