import React from 'react';
import { useHistory, generatePath, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { ROUTES } from '../../constants';
import { Input } from '../../components/forms';

const initialValues = {
	title: '',
};

const IntensityCategoryCreateSchema = Yup.object().shape({
	title: Yup.string()
		.required('Please enter a title.')
		.max(64, 'Please enter a shorter description.'),
});

const IntensityCategoryCreate = () => {
	const history = useHistory();

	const handleSubmit = async (values, { setSubmitting }) => {
		const { data: { data: intensityCategory } } = await axios.post('/api/v1/admin/intensity/category', {
			...values,
		});
		setSubmitting(false);
		history.push(generatePath(ROUTES.INTENSITY_CATEGORY_EDIT, {
			intensityCategoryId: intensityCategory.id
		}));
	};

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Create Intensity Category</h1>
				</div>
			</div>
			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					<Formik
						initialValues={initialValues}
						validationSchema={IntensityCategoryCreateSchema}
						validateOnBlur={false}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form disabled={isSubmitting}>
								<div className="divide-y divide-gray-200">
									<div className="grid gap-x-4 pb-8">
										<Input id="title" label={<span>Title<sup>*</sup></span>} name="title" autofocus disabled={isSubmitting} />
									</div>
									<div className="bt-1 pt-8">
										<div className="flex justify-end">
											<Link
												as="button"
												to={ROUTES.INTENSITY_CATEGORY_LIST}
												className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Cancel
											</Link>
											<button
												type="submit"
												className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Create
											</button>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default IntensityCategoryCreate;
