import axios from './axios';

const auth = {
	username: 'hirunner-admin-ui',
	password: '',
};

export async function login(email, password) {
	const searchParams = new URLSearchParams({
		username: email,
		password,
		grant_type: 'password',
	});
	const { data: tokens } = await axios.post('/api/oauth/token', searchParams.toString(), {
		auth,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	});
	return tokens;
}

export async function refresh(refreshToken) {
	const searchParams = new URLSearchParams({
		grant_type: 'refresh_token',
		refresh_token: refreshToken,
	});
	return axios.post('/api/oauth/token', searchParams.toString(), {
		auth,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
}
