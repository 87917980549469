import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { Avatar } from '../../components/runners';
import { Table } from '../../components/table';
import { ROUTES } from '../../constants';

const columns = [
	{
		field: 'profileImageUrl',
		label: '',
		headerClassName: 'w-8 pr-0',
		cellClassName: 'pr-0',
		cell: (value, row) => <Avatar runner={row} className="h-8 w-8" />,
	},
	{
		field: 'email',
		label: 'E-Mail',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'firstName',
		label: 'First name',
	},
	{
		field: 'lastName',
		label: 'Last name',
	},
	{
		field: 'status',
		label: 'Status',
	},
];

const filters = [
	{
		field: 'email',
		type: 'text',
	},
	{
		field: 'firstName',
		type: 'text',
	},
	{
		field: 'lastName',
		type: 'text',
	},
	{
		field: 'status',
		type: 'select',
		values: [
			{ value: '', label: '(please select)' },
			{ value: 'Active', label: 'Active' },
			{ value: 'Terminated', label: 'Terminated' },
		],
	},
];

const initialFilters = [
	{ field: 'status', value: 'Active' },
];

const RunnerList = () => {
	const history = useHistory();
	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Runners</h1>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="py-4">
					<Table
						url="/api/v1/admin/runners"
						columns={columns}
						filters={filters}
						initialFilters={initialFilters}
						onEditClick={(row) => history.push(generatePath(ROUTES.RUNNER_EDIT, { runnerId: row.id }))}
					/>
				</div>
			</div>
		</>
	);
};

export default RunnerList;
