/* global Hls */

import React from 'react';
import { ErrorMessage, useField } from 'formik';
import Editor from '@stfy/react-editor.js'
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import classNames from 'classnames';

import RunDetailsTool from './RunDetailsTool';
import axios from '../../api/axios';

import './RichEditorField.css';

function make(tagName, classNames = null, attributes = {}) {
	const el = document.createElement(tagName);
	if (Array.isArray(classNames)) {
		el.classList.add(...classNames);
	} else if (classNames) {
		el.classList.add(classNames);
	}
	for (const attrName in attributes) {
		el[attrName] = attributes[attrName];
	}
  	return el;
};

class ImageExt extends Image {

	constructor({ data, config, api, readOnly }) {
		super({ data, config, api, readOnly });
		this.ui.fillImage = function(url, contentType) {
			const tag = contentType.indexOf('image/') !== -1 ? 'IMG' : 'VIDEO';

			const attributes = {};

			// We use eventName variable because IMG and VIDEO tags have different event to be called on source load
			// IMG: load
			// VIDEO: loadeddata
			let eventName = 'load';

			if (tag === 'VIDEO') {
				attributes.controls = true;
				attributes.autoplay = false;
				attributes.loop = true;
				attributes.muted = true;
				attributes.playsinline = true;

				// Change event to be listened
				eventName = 'loadeddata';
			} else {
				attributes.src = url;
			}

			this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);

			if (tag === 'VIDEO') {
				if (url.indexOf('m3u8') === -1 || this.nodes.imageEl.canPlayType('application/vnd.apple.mpegurl')) {
					this.nodes.imageEl.src = url;
				} else if (Hls.isSupported()) {
					const hls = new Hls();
					hls.loadSource(url);
					hls.attachMedia(this.nodes.imageEl);
				}
			}

			this.nodes.imageEl.addEventListener(eventName, () => {
				this.toggleStatus('filled');

				if (this.nodes.imagePreloader) {
					this.nodes.imagePreloader.style.backgroundImage = '';
				}
			});

			this.nodes.imageContainer.appendChild(this.nodes.imageEl);
		  }
	}

	onUpload(response) {
		if (response.mediaId) {
			this.image = {
				mediaId: response.mediaId,
				width: response.width,
				height: response.height,
			};
		} else {
			this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
		}
	}

	get data() {
		return super.data;
	}

	set data(data) {
		super.data = data;
		this.image = {
			mediaId: data.mediaId,
			width: data.width,
			height: data.height,
		};
	}

	set image(image) {
		const mediaId = image?.mediaId;
		const width = image?.width;
		const height = image?.height;

		this._data = {
			mediaId,
			width,
			height
		};

		if (mediaId) {
			const loadImage = async () => {
				const { data: { data: image } } = await axios.get(`/api/v1/admin/media/${mediaId}`);
				this.ui.fillImage(image.url, image.contentType);
			}
			loadImage();
		}
	}

};

const RichEditorField = ({ onMediaUpload, autofocus, placeholder, ...props }) => {
	const [, meta, helpers] = useField(props);
	const { className, id, label, hideLabel = false, name } = props;
	const { touched, value, error } = meta;
	const { setValue } = helpers;

	const defaultConfig = {
		minHeight: 0,
		initialBlock: 'header',
	};

	const imageConfig = {
		uploader: {
			uploadByFile: onMediaUpload,
		},
		types: 'image/*,video/*',
	};
	const defaultTools = {
		header: Header,
		list: List,
		image: {
			class: ImageExt,
			config: imageConfig,
		},
		[RunDetailsTool.blockName]: RunDetailsTool,
	};

	return (
		<div className={classNames(className)}>
			{!hideLabel && (
				<label htmlFor={id} className="block text-sm font-medium text-gray-700">
					{label}
				</label>
			)}
			<div className={classNames(
				touched && error && 'is-invalid',
				'mt-1 border rounded-md shadow-sm border-gray-300 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 p-2',
			)}>
				<Editor
					autofocus={autofocus}
					placeholder={placeholder}
					config={defaultConfig}
					reinitOnPropsChange={false}
					data={value}
					tools={defaultTools}
					onData={(data) => setValue(data)}
				/>
			</div>
			{touched && error && (
				<p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
					<ErrorMessage name={name} />
				</p>
			)}
		</div>
	)
};

export default RichEditorField;
