import React, { useState, useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { parseISO, format, parse, isBefore, isAfter, endOfMonth, getYear, getMonth, addMonths, setDate, differenceInMonths } from 'date-fns';
import { toast } from 'react-hot-toast';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/solid';

import axios from '../../api/axios';
import { Calendar } from '../../components/plans';
import { ROUTES, ISO_DATE } from '../../constants';

const PlanEdit = ({ match }) => {
	const [plan, setPlan] = useState();
	const [owner, setOwner] = useState();

	const [minDate, setMinDate] = useState();
	const [maxDate, setMaxDate] = useState();
	const [nextTrainingDay, setNextTrainingDay] = useState();
	const [months, setMonths] = useState([]);
	const [initialPage, setInitialPage] = useState(0);

	const { planId } = match.params;

	useEffect(() => {
		const loadPlanDetailsAsync = async () => {
			const { data: { data: plan } } = await axios.get(`/api/v1/admin/plan/${planId}`, {
				params: {
					includeDetails: true,
				},
			});
			setPlan(plan);
			const { data: { data: owner } } = await axios.get(`/api/v1/admin/runners/${plan.owner.id}`);
			setOwner(owner);
		}
		loadPlanDetailsAsync();
	}, [planId]);

	useEffect(() => {
		if (plan && plan.details.trainingDays) {
			const nowDate = new Date();
			// Determine min/max dates for this plan
			plan.details.trainingDays.sort((d1, d2) => (d1.date > d2.date ? 1 : d1.date < d2.date ? -1 : 0));
			let newMinDate = parse(plan.details.trainingDays[0].date, ISO_DATE, nowDate);
			let newMaxDate = parse(plan.details.trainingDays[plan.details.trainingDays.length - 1].date, ISO_DATE, nowDate);
			setMinDate(newMinDate);
			setMaxDate(newMaxDate);
			// Determine next training day
			nowDate.setHours(0, 0, 0, 0); // Clear time so that training day "today" is still a match.
			let newNextTrainingDay = null;
			for (let trainingDay of plan.details.trainingDays) {
				const trainingDate = parse(trainingDay.date, ISO_DATE, nowDate);
				if (!isBefore(trainingDate, nowDate)) {
					if (newNextTrainingDay === null || isBefore(trainingDate, newNextTrainingDay)) {
						newNextTrainingDay = trainingDate;
					}
				}
			}
			if (newNextTrainingDay === null) {
				const trainingDate = parse(plan.details.trainingDays[plan.details.trainingDays.length - 1].date, ISO_DATE, nowDate);
				if (newNextTrainingDay === null || isAfter(trainingDate, newNextTrainingDay)) {
					newNextTrainingDay = trainingDate;
				}
			}
			setNextTrainingDay(newNextTrainingDay);
		}
	}, [plan]);

	useEffect(() => {
		if (minDate && maxDate) {
			const newMonths = [];
			let date = minDate;
			const max = endOfMonth(maxDate);
			while (isBefore(date, max)) {
				const year = getYear(date);
				const month = getMonth(date);
				newMonths.push({
					year,
					month,
					key: `${year}-${month}`,
				});
				date = addMonths(date, 1);
			}
			setMonths(newMonths);

			if (nextTrainingDay === null) {
				return;
			}

			const minDateFirstOfMonth = setDate(minDate, 1);
			const nextTrainingDayFirstOfMonth = setDate(nextTrainingDay, 1);
			let difference = differenceInMonths(nextTrainingDayFirstOfMonth, minDateFirstOfMonth);
			setInitialPage(difference);
		}
	}, [minDate, maxDate, nextTrainingDay]);

	const pausePlan = async () => {
		try {
			const { data: { data: plan } } = await axios.post(`/api/v1/admin/plan/${planId}:pause`);
			setPlan(plan);
			toast.success('Plan successfully paused.');
		} catch {
			toast.error('An error occurred, please try again.');
		}
	};

	const unpausePlan = async () => {
		try {
			const { data: { data: plan } } = await axios.post(`/api/v1/admin/plan/${planId}:unpause`);
			setPlan(plan);
			toast.success('Plan successfully unpaused.');
		} catch {
			toast.error('An error occurred, please try again.');
		}
	}

	return (
		<>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 md:flex md:items-center md:justify-between">
				<div className="flex items-center space-x-5">
					<h1 className="text-2xl font-semibold text-gray-900">Edit Plan {plan?.id}</h1>
				</div>
				<div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
					{(plan?.status === 'Active' || plan?.status === 'Created') && (
						<button
							type="button"
							className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cyan-500"
							onClick={() => pausePlan()}
						>
							Pause
						</button>
					)}
					{plan?.status === 'Paused' && (
						<button
						type="button"
						className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cyan-500"
						onClick={() => unpausePlan()}
					>
						Unpause
					</button>
					)}
				</div>
			</div>

			<div className="my-8 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
				<div className="bg-white shadow sm:rounded-lg">
					<div className="px-4 py-5 sm:px-6">
						<h2 className="text-lg leading-6 font-medium text-gray-900">Plan Details</h2>
						<p className="mt-1 max-w-2xl text-sm text-gray-500">
							{plan ? (
								<Link className="font-medium text-cyan-600 hover:text-cyan-900" to={generatePath(ROUTES.PLAN_CONFIG_EDIT, { planConfigId: plan.planConfigId })}>{plan.details.title}</Link>
							) : `Plan ${planId}`}
							{owner && (
								<>
									{' owned by '}
									<Link className="font-medium text-cyan-600 hover:text-cyan-900" to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: owner.id })}>{owner.firstName}</Link>
								</>
							)}
							{plan?.trial === true && <LockClosedIcon className="ml-1 h-4 w-4 inline" aria-hidden="true" aria-label="Trial mode" />}
							{plan?.trial === false && <LockOpenIcon className="ml-1 h-4 w-4 inline" aria-hidden="true" aria-label="Full version" />}
						</p>
					</div>
					<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
						{plan && (
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">ID</dt>
									<dt className="mt-1 text-sm text-gray-900">{plan.id}</dt>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">Created</dt>
									<dt className="mt-1 text-sm text-gray-900">{format(parseISO(plan.creationDateTime), 'dd/MM/yyyy HH:mm:ss')}</dt>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">End date</dt>
									<dt className="mt-1 text-sm text-gray-900">{format(parseISO(plan.endDate), 'dd/MM/yyyy')}</dt>
								</div>
								<div className="sm:col-span-3">
									<dt className="text-sm font-medium text-gray-500">Status</dt>
									<dt className="mt-1 text-sm text-gray-900">
										{plan.status}
										{plan.status === 'Paused' && (
											` (since ${format(parseISO(plan.pauseDate), 'dd/MM/yyyy')})`
										)}
										{plan.status === 'Terminated' && (
											` (on ${format(parseISO(plan.terminationDateTime), 'dd/MM/yyyy HH:mm:ss')})`
										)}
									</dt>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">Training Days</dt>
									<td className="mt-1 text-sm text-gray-900">
										{plan.trainingDays.map((trainingDay, index) => `${index > 0 ? ', ' : ''}${trainingDay}`)}
									</td>
								</div>
							</dl>
						)}
					</div>
				</div>
			</div>

			<div className="my-8 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
				<div className="bg-white shadow sm:rounded-lg">
					{months.length && (
						<Calendar
							plan={plan}
							months={months}
							initialMonth={initialPage}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default PlanEdit;
