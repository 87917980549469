import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';

import axios from '../../api/axios';
import { Input } from '../../components/forms';
import { ROUTES } from '../../constants';

const MediaEditSchema = Yup.object().shape({
});

const MediaEdit = ({ match }) => {
	const [media, setMedia] = useState();
	const [muxSync, setMuxSync] = useState(null);
	const { mediaId } = match.params;

	useEffect(() => {
		const loadMediaDetailsAsync = async () => {
			const { data: { data: result } } = await axios.get(`/api/v1/admin/media/${mediaId}`);
			setMedia(result);
		}
		loadMediaDetailsAsync();
	}, [mediaId]);

	useEffect(() => {
		const loadMuxSyncAsync = async () => {
			try {
				const { data: { data: result } } = await axios.get(`/api/v1/admin/media/muxsync/${mediaId}`);
				setMuxSync(result);
			} catch (e) {
				setMuxSync(false);
			}
		}
		loadMuxSyncAsync();
	}, [mediaId]);

	const startMuxSync = async () => {
		const { data: { data: result } } = await axios.post(`/api/v1/admin/media/muxsync/${mediaId}`);
		setMuxSync(result);
	};

	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Edit Media {media?.id}</h1>
				</div>
			</div>
			<div className="grid grid-cols-3 gap-x-4 px-4 py-4 sm:px-6 md:px-8">
				{/* Left part, details */}
				<div className="col-span-2">
					<div className="pb-4">
						<h2 className="text-xl font-semibold text-gray-900">Details</h2>
					</div>
					<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
						{media && (
							<Formik
								initialValues={media}
								validationSchema={MediaEditSchema}
								validateOnBlur={false}
								onSubmit={() => {}}
							>
								{({ isSubmitting }) => (
									<Form disabled={isSubmitting}>
										<div className="divide-y divide-gray-200">
											<div>
												<div className="grid grid-cols-2 gap-x-4 pb-4">
													<Input id="id" label="ID" name="id" disabled />
													<Input id="creationDateTime" label="Created" name="creationDateTime" disabled />
												</div>
												<Input className="pb-4" id="contentType" label="Content Type" name="contentType" disabled />
												<Input className="pb-4" id="type" label="Type" name="type" disabled />
												<Input className="pb-4" id="status" label="Status" name="status" disabled />
											</div>
											<div className="bt-1 pt-8">
												<div className="flex justify-end">
													<Link
														as="button"
														to={ROUTES.MEDIA_LIST}
														className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
													>
														Cancel
													</Link>
													<button
														type="submit"
														className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
													>
														Save
													</button>
												</div>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						)}
					</div>
				</div>
				{/* Right part */}
				<div className="col-span-1">
					{media?.contentType.startsWith('video/') && (
						<>
							<div className="pb-4">
								<h2 className="text-xl font-semibold text-gray-900">Mux Synchronization</h2>
							</div>
							{muxSync === false && (
								<button
									type="button"
									className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
									onClick={startMuxSync}
								>
									Synchronize
								</button>
							)}
							{muxSync === true && (
								<p>Mux Asset ID {muxSync.assetId}, playback ID {muxSync.playbackId}, synchronized {format(parseISO(muxSync.creationDateTime), 'dd/MM/yyyy HH:mm:ss')}</p>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default MediaEdit;
