import React from 'react';
import { useHistory, generatePath, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { ROUTES } from '../../constants';
import { Input, Select } from '../../components/forms';

const initialValues = {
	type: '',
	identifier: '',
	title: '',
	duration: '',
};

const ExerciseCreateSchema = Yup.object().shape({
	type: Yup.string()
		.required('Please select a type.'),
	identifier: Yup.string()
		.required('Please enter an identifier.')
		.max(32, 'Please enter a shorter identifier.'),
	title: Yup.string()
		.required('Please enter a title.')
		.max(255, 'Please enter a shorter description.'),
	duration: Yup.number()
		.typeError('Please enter a positive number.')
		.required('Please enter a duration.')
		.positive('Please enter a positive number.')
		.integer('Please enter an integer.'),
});

const ExerciseCreate = () => {
	const history = useHistory();

	const handleSubmit = async (values, { setSubmitting }) => {
		const { data: { data: exercise } } = await axios.post('/api/v1/admin/exercise', {
			...values,
		});
		setSubmitting(false);
		history.push(generatePath(ROUTES.EXERCISE_EDIT, {
			exerciseId: exercise.id
		}));
	};

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Create Exercise</h1>
				</div>
			</div>
			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					<Formik
						initialValues={initialValues}
						validationSchema={ExerciseCreateSchema}
						validateOnBlur={false}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form disabled={isSubmitting}>
								<div className="divide-y divide-gray-200">
									<div>
										<Select className="pb-4" id="type" label={<span>Type<sup>*</sup></span>} name="type" disabled={isSubmitting}>
											<option>Please select...</option>
											<option value="WarmUp">Warm Up</option>
											<option value="Mobilization">Mobilization</option>
											<option value="Power">Power</option>
											<option value="Coordination">Coordination</option>
											<option value="Intro">Intro</option>
											<option value="Run">Run</option>
											<option value="WarmDown">Warm Down</option>
											<option value="FasciaTraining">Fascia Training</option>
										</Select>
										<Input className="pb-4" id="identifier" label={<span>Identifier<sup>*</sup></span>} name="identifier" disabled={isSubmitting} />
										<Input className="pb-4" id="title" label={<span>Title<sup>*</sup></span>} name="title" disabled={isSubmitting} />
										<Input className="pb-4" id="duration" label={<span>Duration (seconds)<sup>*</sup></span>} name="duration" disabled={isSubmitting} />
									</div>
									<div className="bt-1 pt-8">
										<div className="flex justify-end">
											<Link
												as="button"
												to={ROUTES.EXERCISE_LIST}
												className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Cancel
											</Link>
											<button
												type="submit"
												className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Create
											</button>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default ExerciseCreate;
