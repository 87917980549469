import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from '../../api/axios';
import { ROUTES } from '../../constants';
import { EventDescription } from '../events';

const LastEventsCard = () => {
	const [lastEvents, setLastEvents] = useState([]);

	useEffect(() => {
		const loadLastEventsAsync = async () => {
			const { data: { data: events } } = await axios.get('/api/v1/admin/event', {
				params: {
					sort: 'timestamp:desc',
					page: 0,
					size: 10,
				},
			});
			setLastEvents(events);
		};
		loadLastEventsAsync();
	}, []);

	return (
		<div className="break-inside h-auto pb-6">
			<div className="bg-white overflow-hidden shadow rounded-lg">
				<div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:pl-6 lg:pr-4">
					<div className="flex-1 min-w-0">
						<h1 className="text-lg font-medium leading-6 text-gray-800 sm:truncate">Last Events</h1>
					</div>
					<div className="mt-4 flex sm:mt-0 sm:ml-4">
					</div>
				</div>
				<table className="divide-y divide-gray-200 w-full table-fixed">
					<thead className="bg-gray-50">
						<tr>
							<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Description
							</th>
						</tr>
					</thead>
					<tbody>
						{lastEvents.map((item, index) => (
							<tr key={`lastEvent-${item.id}`} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
									<EventDescription event={item} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="bg-gray-50 px-5 py-3 border-t border-gray-200">
					<div className="text-sm text-right">
						<Link to={ROUTES.EVENT_LIST} className="font-medium text-cyan-700 hover:text-cyan-900">
							View Events
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LastEventsCard;
