import React from 'react';
import { Formik, Form } from 'formik';

import { Input } from '../forms';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';

const PlanEditSchema = Yup.object().shape({
	// title: Yup.string()
	// 	.required('Please enter a title.'),
});

const PlanEditForm = ({ plan, onPlanSubmit, showCancel }) => (
	<Formik
		initialValues={plan}
		validationSchema={PlanEditSchema}
		validateOnBlur={false}
		onSubmit={onPlanSubmit}
		enableReinitialize
	>
		{({ isSubmitting }) => (
			<Form disabled={isSubmitting}>
				<div className="divide-y divide-gray-200">
					<div>
						<div className="grid grid-cols-2 gap-x-4 pb-4">
							<Input id="id" label="ID" name="id" disabled />
							<Input id="creationDateTime" label="Created" name="creationDateTime" disabled />
						</div>
						<div className="grid grid-cols-2 gap-x-4 pb-4">
							<Input id="status" label="Status" name="status" disabled />
							<Input id="pauseDate" label="Pause date" name="pauseDate" disabled />
						</div>
					</div>
					<div className="bt-1 pt-8">
						<div className="flex justify-end">
							{showCancel && <Link
								as="button"
								to={ROUTES.PLAN_LIST}
								className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
							>
								Cancel
							</Link>}
							<button
								type="submit"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
							>
								Update
							</button>
						</div>
					</div>
				</div>
			</Form>
		)}
	</Formik>
);

export default PlanEditForm;
