import React, { useState } from 'react';
import CalendarPage from './CalendarPage';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const MONTH_NAMES = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
const WEEKDAYS = [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ];

const Calendar = ({ plan, months, initialMonth }) => {
	const [currentMonth, setCurrentMonth] = useState(initialMonth);
	const [constrainHeight, setConstrainHeight] = useState(true);

	return (
		<>
			<div className="flex items-center justify-between py-2 px-6">
				<div className="flex-grow">
					<span className="text-lg font-medium text-gray-800">{MONTH_NAMES[months[currentMonth].month]}</span>
					<span className="ml-1 text-lg text-gray-500 font-normal">{months[currentMonth].year}</span>
				</div>
				<div className="relative flex items-start mr-4">
					<div className="flex items-center h-5">
						<input
							id="constrain-cells"
							aria-describedby="constrain-cells-description"
							name="constrain-cells"
							type="checkbox"
							className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300 rounded"
							checked={constrainHeight}
							onChange={(evt) => setConstrainHeight(evt.target.checked)}
						/>
					</div>
					<div className="ml-3 text-sm">
						<label htmlFor="constrain-cells" className="font-medium text-gray-700">
							Constrain height
						</label>
						<span id="constrain-cells-description" className="text-gray-500">
							<span className="sr-only">Constrain height</span> of calendar cells
						</span>
					</div>
				</div>
				<div className="border rounded-lg flex">
					<button
						type="button"
						className={classNames(
							'leading-none rounded-lg transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 items-center',
							currentMonth === 0 && 'cursor-not-allowed opacity-25'
						)}
						disabled={currentMonth === 0}
						onClick={() => setCurrentMonth(currentMonth - 1)}
					>
						<ChevronLeftIcon className="w-5 h-5" />
					</button>
					<button
						type="button"
						className={classNames(
							'leading-none rounded-lg transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 items-center',
							currentMonth === months.length - 1 && 'cursor-not-allowed opacity-25'
						)}
						disabled={currentMonth === months.length - 1}
						onClick={() => setCurrentMonth(currentMonth + 1)}
					>
						<ChevronRightIcon className="w-5 h-5" />
					</button>
				</div>
			</div>
			<div className="grid grid-cols-7 gap-0">
				{WEEKDAYS.map((weekday, index) => (
					<div key={`weekday-${index}`} className="py-2 text-center text-gray-500">
						{weekday}
					</div>
				))}
			</div>
			<CalendarPage
				plan={plan}
				year={months[currentMonth].year}
				month={months[currentMonth].month}
				constrainHeight={constrainHeight}
			/>
		</>
	);
};

export default Calendar;
