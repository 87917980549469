import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { format, parseISO } from 'date-fns';

import axios from '../../api/axios';
import { Table } from '../../components/table';
import { Avatar, PersonalDetails, ActivePlans } from '../../components/runners';
import TerminateRunnerModal from './TerminateRunnerModal';

const mobileDeviceTableColumns = [
	{
		field: 'id',
		label: 'ID',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'creationDateTime',
		label: 'Created',
		format: 'timestamp',
	},
	{
		field: 'status',
		label: 'Status',
	},
	{
		field: 'os',
		label: 'OS',
	},
	{
		field: 'deviceId',
		label: 'Device ID',
	},
	{
		field: 'pnsStatus',
		label: 'PNS Status',
	},
];

const RunnerEdit = ({ match }) => {
	const [, setLoading] = useState(true);
	const [runner, setRunner] = useState();
	const [activePlans, setActivePlans] = useState();
	const [showTerminateModal, setShowTerminateModal] = useState(false);
	const { runnerId } = match.params;

	useEffect(() => {
		const loadRunnerAsync = async () => {
			const { data: { data: loadedRunner } } = await axios.get(`/api/v1/admin/runners/${runnerId}`);
			setRunner(loadedRunner);
			setLoading(false);
		}
		loadRunnerAsync();
	}, [runnerId]);

	useEffect(() => {
		const loadActivePlansAsync = async () => {
			const { data: { data: loadedPlans } } = await axios.get(`/api/v1/admin/plan`, {
				params: {
					ownerType: 'Runner',
					ownerId: runnerId,
					status: 'Created|Active|Paused',
					includeDetails: true,
				},
			});
			setActivePlans(loadedPlans);
		}
		loadActivePlansAsync();
	}, [runnerId]);

	const updateRunner = async (values, { setSubmitting }) => {
		try {
			const { data: { data: result } } = await axios.put(`/api/v1/admin/runners/${runnerId}`, {
				email: values.email,
				firstName: values.firstName,
				lastName: values.lastName,
				dateOfBirth: values.dateOfBirth,
				height: values.height,
				weight: values.weight,
				emailRemindersOn: values.emailRemindersOn,
				pushRemindersOn: values.pushRemindersOn,
			});
			setRunner(result);
			toast.success('The runner was successfully updated.');
		} finally {
			setSubmitting(false);
		}
	};

	const terminateRunner = async (sendEmail) => {
		try {
			const { data: { data: result } } = await axios.post(`/api/v1/admin/runners/${runnerId}:terminate`, {
				sendEmail,
			});
			setShowTerminateModal(false);
			setRunner(result);
			toast.success('The runner\'s account was successfully terminated.');
		} catch (_) {
			toast.error('An error occurred, please try again.');
		}
	};

	if (!runner) {
		return null;
	}

	return (
		<>
			{/* Header */}
			<div className="mx-auto mb-4 px-8 flex items-center justify-between space-x-5">
				<div className="flex items-center space-x-5">
					<div className="flex-shrink-0">
						<Avatar runner={runner} className="w-16 h-16" />
					</div>
					<div>
						<h1 className="text-2xl font-bold text-gray-900">{runner?.firstName} {runner?.lastName}</h1>
						<p className="text-sm font-medium text-gray-500">
							is a HiRunner since <span className="text-gray-900">{format(parseISO(runner.creationDateTime), 'dd/MM/yyyy')}</span>
						</p>
					</div>
				</div>
				<div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
					<button
						disabled={runner.status === 'Terminated'}
						onClick={() => setShowTerminateModal(true)}
						className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
					>
						Terminate
					</button>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-x-4 px-4 py-4 sm:px-6 md:px-8 mb-8">
				{/* Left part, details */}
				<div>
					<PersonalDetails runner={runner} onUpdateRunner={updateRunner} />
				</div>
				{/* Right part */}
				<div>
					<ActivePlans runner={runner} activePlans={activePlans} />
				</div>
			</div>

			<div className="mx-auto px-8">
				<div className="pb-5 border-b border-gray-200">
					<h2 className="text-xl font-semibold text-gray-900">Mobile Devices</h2>
				</div>
			</div>
			<div className="mx-auto px-8 py-4">
				<Table
					url="/api/v1/admin/mobiledevice"
					defaultParams={{
						ownerType: 'Runner',
						ownerId: runner.id,
					}}
					columns={mobileDeviceTableColumns}
					onEditClick={(row) => {}}
				/>
			</div>

			<TerminateRunnerModal show={showTerminateModal} onConfirmTerminate={terminateRunner} onHide={() => setShowTerminateModal(false)} />
		</>
	);

};

export default RunnerEdit;
