import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const generatePages = (total, page) => {
	const pages = [];
	if (!total) {
		return pages;
	}
	pages.push({
		type: 'previous',
	});
	pages.push({
		type: 'page',
		page: 0,
	});
	if (page - 3 > 1) {
		pages.push({
			type: 'ellipsis',
		});
	}
	for (let i = Math.max(1, page - 3); i <= Math.min(page + 3, total - 1); i++) {
		pages.push({
			type: 'page',
			page: i,
		});
	}
	if (page + 3 < total - 3) {
		pages.push({
			type: 'ellipsis',
		});
	}
	if (page + 3 < total - 1) {
		pages.push({
			type: 'page',
			page: total - 1,
		});
	}
	pages.push({
		type: 'next',
	});
	return pages;
};

const Pagination = ({ currentItemCount, itemsPerPage, pageIndex, startIndex, totalItems, totalPages, onPageChange }) => {
	const buttons = generatePages(totalPages, pageIndex);
	return (
		<div>
			<nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
				{buttons.map((button, index) => {
					if (button.type === 'previous') {
						return (
							<button
								key={`item-${index}`}
								onClick={() => onPageChange(Math.max(0, pageIndex - 1))}
								className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
							>
								<span className="sr-only">Previous</span>
								<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						);
					} else if (button.type === 'ellipsis') {
						return (
							<span key={`item-${index}`} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
								...
							</span>
						);
					} else if (button.type === 'page') {
						const buttonProps = {};
						if (button.page === pageIndex) {
							buttonProps['aria-current'] = 'page';
							buttonProps['className'] = 'z-10 bg-cyan-50 border-cyan-500 text-cyan-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium';
						} else {
							buttonProps['className'] = 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium';
						}
						return (
							<button
								key={`item-${index}`}
								onClick={() => onPageChange(button.page)}
								{...buttonProps}
							>
								{button.page + 1}
							</button>
						);
					} else if (button.type === 'next') {
						return (
							<button
								key={`item-${index}`}
								onClick={() => onPageChange(Math.min(totalPages - 1, pageIndex + 1))}
								className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
							>
								<span className="sr-only">Next</span>
								<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						);
					} else {
						return null;
					}
				})}
			</nav>
		</div>
	);
};

export default Pagination;
