import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { format, set } from 'date-fns';

import { ROUTES } from '../../constants';

const formatDate = (dob) => dob ? `${dob.day}/${dob.month}/${dob.year}` : '';
const formatReminderTime = (reminderTime) => format(set(new Date(), { hours: Math.floor(reminderTime / 60), minutes: reminderTime % 60 }), 'HH:mm');

const EventDescription = ({ event }) => {
	switch (event?.type) {
		case 'RunnerSignedUp':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> registered.
				</span>
			);
		case 'RunnerTerminated':
			return (
				<span>
					The account of Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> was terminated.
				</span>
			);
		case 'EmailAddressConfirmed':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> confirmed their email address <span className="font-medium text-gray-900">{event.payload.email}</span>.
				</span>
			);
		case 'EmailAddressUpdated':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> updated their email address to <span className="font-medium text-gray-900">{event.payload.email}</span>.
				</span>
			);
		case 'PersonalDetailsUpdated':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> updated their personal details <span className="font-medium text-gray-900">(firstName: {event.payload.firstName}, lastName: {event.payload.lastName}, dateOfBirth: {formatDate(event.payload.dateOfBirth)}, height: {event.payload.height} cm, weight: {event.payload.weight} kg)</span>.
				</span>
			);
		case 'RemindersUpdated':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> updated their reminders to <span className="font-medium text-gray-900">email reminders: {event.payload.emailRemindersOn ? 'on' : 'off'}, push reminders: {event.payload.pushRemindersOn ? 'on' : 'off'}, day: {event.payload.reminderDay}, time: {formatReminderTime(event.payload.reminderTime)}</span>.
				</span>
			);
		case 'ProfileImageUploaded':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> uploaded a new profile image.
				</span>
			);
		case 'ProfileImageDeleted':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> deleted their profile image.
				</span>
			);
		case 'PlanCreated':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> started the plan <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900">{event.payload.title}</Link>.
				</span>
			);
		case 'PlanPaused':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> paused <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'PlanUnpaused':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> unpaused <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'TrainingDaysUpdated':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> updated the training days of <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'PlanTerminated':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> terminated <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'PlanCompletionConfirmed':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> confirmed the completion of <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'ExerciseDone':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> marked exercises [{event.payload.exerciseIdentifiers.join(', ')}] of <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link> as done for date <span className="font-medium text-gray-900">{formatDate(event.payload.date)}</span>.
				</span>
			);
		case 'ExerciseUndone':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> marked exercises [{event.payload.exerciseIdentifiers.join(', ')}] of <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link> as undone for date <span className="font-medium text-gray-900">{formatDate(event.payload.date)}</span>.
				</span>
			);
		case 'ExerciseFavorited':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> added exercises [{event.payload.exerciseIdentifiers.join(', ')}] to their favorites.
				</span>
			);
		case 'ExerciseUnfavorited':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> removed exercises [{event.payload.exerciseIdentifiers.join(', ')}] from their favorites.
				</span>
			);
		case 'ExerciseFeedbackGiven':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> submitted <Link to={generatePath(ROUTES.EXERCISE_FEEDBACK_EDIT, { exerciseFeedbackId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">feedback</Link> with value <span className="font-medium text-gray-900">{event.payload.value}</span>{' '}
					{event.payload.comment && (
						<>and comment <span className="font-medium text-gray-900">{event.payload.comment}...</span>{' '}</>
					)}
					for exercise <span className="font-medium text-gray-900">{event.payload.identifier}</span> in <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.payload.planId })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'PurchaseStarted':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> started <Link to={generatePath(ROUTES.PURCHASE_EDIT, { purchaseId: event.payload.purchaseId })} className="font-medium text-gray-900 underline">the purchase</Link> of <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'PurchaseCompleted':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> completed <Link to={generatePath(ROUTES.PURCHASE_EDIT, { purchaseId: event.payload.purchaseId })} className="font-medium text-gray-900 underline">the purchase</Link> of <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		case 'PurchaseCanceled':
			return (
				<span>
					Runner <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: event.originator.id })} className="font-medium text-gray-900 underline">{event.payload.firstName}</Link> canceled <Link to={generatePath(ROUTES.PURCHASE_EDIT, { purchaseId: event.payload.purchaseId })} className="font-medium text-gray-900 underline">the purchase</Link> of <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: event.referencedEntity.id })} className="font-medium text-gray-900 underline">their plan</Link>.
				</span>
			);
		default:
			return (
				<span>Event type <span className="font-medium text-gray-900">{event.type}</span> is not yet supported.</span>
			);
	}
};

export default EventDescription;