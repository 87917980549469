import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/solid';
import React, { useState, useEffect } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import axios from '../../api/axios';
import { Table } from '../../components/table';
import { ROUTES } from '../../constants';

const PlanConfigCell = ({ planConfigId }) => {
	const [planConfig, setPlanConfig] = useState();
	useEffect(() => {
		const loadPlanConfigAsync = async () => {
			const { data: { data: loadedPlanConfig } } = await axios.get(`/api/v1/admin/planconfig/${planConfigId}`);
			setPlanConfig(loadedPlanConfig);
		};
		loadPlanConfigAsync();
	}, [planConfigId]);

	if (!planConfig) {
		return <span>Loading...</span>;
	} else {
		return <Link to={generatePath(ROUTES.PLAN_CONFIG_EDIT, { planConfigId })}>{planConfig.title}</Link>;
	}
};

const OwnerCell = ({ owner: ownerTypedId }) => {
	const [owner, setOwner] = useState();
	useEffect(() => {
		const loadOwnerAsync = async () => {
			const { data: { data: loadedOwner } } = await axios.get(`/api/v1/admin/runners/${ownerTypedId.id}`);
			setOwner(loadedOwner);
		};
		if (ownerTypedId.type === 'Runner') {
			loadOwnerAsync();
		}
	}, [ownerTypedId]);
	if (!owner) {
		return <span>{ownerTypedId.type} {ownerTypedId.id}</span>
	} else {
		return <Link className="underline" to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: owner.id })}>{owner.firstName}</Link>;
	}
};

const columns = [
	{
		field: 'id',
		label: 'ID',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'creationDateTime',
		label: 'Created',
		format: 'timestamp',
	},
	{
		field: 'status',
		label: 'Status',
	},
	{
		field: 'trial',
		label: 'Purchased',
		cell: (trial) => trial ? <LockClosedIcon className="w-4 h-4" /> : <LockOpenIcon className="w-4 h-4" />,
	},
	{
		field: 'planConfigId',
		label: 'Plan Config',
		cell: (planConfigId) => <PlanConfigCell planConfigId={planConfigId} />,
		cellClassName: 'underline',
	},
	{
		field: 'owner',
		label: 'Owner',
		cell: (owner) => <OwnerCell owner={owner} />,
	},
];

const filters = [
	{
		field: 'fromDate',
		type: 'date',
		label: 'From date',
	},
	{
		field: 'untilDate',
		type: 'date',
		label: 'Until date',
	},
	{
		field: 'status',
		type: 'select',
		values: [
			{ value: '', label: '(please select)' },
			{ value: 'Created', label: 'Created' },
			{ value: 'Active', label: 'Active' },
			{ value: 'Paused', label: 'Paused' },
			{ value: 'Completed', label: 'Completed' },
			{ value: 'Confirmed', label: 'Confirmed' },
			{ value: 'Terminated', label: 'Terminated' },
		],
	},
];

const PlanList = () => {
	const history = useHistory();
	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Plans</h1>
					<div className="mt-3 flex sm:mt-0 sm:ml-4">
					</div>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="py-4">
					<Table
						url="/api/v1/admin/plan"
						columns={columns}
						filters={filters}
						onEditClick={(row) => history.push(generatePath(ROUTES.PLAN_EDIT, { planId: row.id }))}
					/>
				</div>
			</div>
		</>
	);
};

export default PlanList;
