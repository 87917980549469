import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Link } from 'react-router-dom';
import { format, subDays } from 'date-fns';

import axios from '../../api/axios';
import { ROUTES } from '../../constants';

const options = {
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			displayColors: false,
			callbacks: {
				title: function() {
					return null;
				},
				label: function(context) {
					return format(new Date(context.parsed.x), 'dd/MM/yyyy') + ': ' + context.parsed.y;
				},
			},
		},
	},
	scales: {
		x: {
			type: 'time',
			time: {
				unit: 'day',
			},
			grid: {
                display : false
            },
		},
		y: {
			ticks: {
				stepSize: 10,
			},
		},
	},
};

const RunnerRegistrationsPerDayCard = () => {
	const [registrationsPerDay, setRegistrationsPerDay] = useState([]);

	useEffect(() => {
		const loadRegistrationsPerDayAsync = async () => {
			const { data: { data: items } } = await axios.get('/api/v1/admin/statistics/registrationsPerDay', {
				params: {
					fromDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
				},
			});
			setRegistrationsPerDay({
				datasets: [
					{
						label: '',
						backgroundColor: '#00c7d9',
						data: items.map((item) => ({ x: item.date, y: item.registrations }))
					},
				],
			});
		};
		loadRegistrationsPerDayAsync();
	}, []);

	return (
		<div className="break-inside h-auto pb-6">
			<div className="bg-white overflow-hidden shadow rounded-lg">
				<div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:pl-6 lg:pr-4">
					<div className="flex-1 min-w-0">
						<h1 className="text-lg font-medium leading-6 text-gray-800 sm:truncate">Runner Registrations</h1>
					</div>
					<div className="mt-4 flex sm:mt-0 sm:ml-4">
						<select
							id="timeframe"
							name="timeframe"
							className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
							defaultValue="last30"
						>
							<option value="last30">Last 30 days</option>
						</select>
						{/* <button type="button" className="order-1 ml-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-0 sm:ml-0">
							Share
						</button> */}
					</div>
				</div>
				<Bar className="p-2" data={registrationsPerDay} options={options} />
				<div className="bg-gray-50 px-5 py-3 border-t border-gray-200">
					<div className="text-sm text-right">
						<Link to={ROUTES.RUNNER_LIST} className="font-medium text-cyan-700 hover:text-cyan-900">
							View Runners
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RunnerRegistrationsPerDayCard;
