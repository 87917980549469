import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { Input, Select } from '../../components/forms';
import { ROUTES } from '../../constants';

const PriceTierEditSchema = Yup.object().shape({
	description: Yup.string()
		.required('Please enter a description.')
		.max(64, 'Please enter a shorter description.'),
	discounts: Yup.array()
		.of(
			Yup.object().shape({
				type: Yup.string()
					.required('Please select a type.'),
				iosSku: Yup.string()
					.when('androidSku', {
						is: androidSku => Boolean(androidSku),
						then: Yup.string()
							.required('Please enter an iOS SKU.')
							.max(64, 'Please enter a shorter iOS SKU.'),
					}),
				androidSku: Yup.string()
					.when('iosSku', {
						is: iosSku => Boolean(iosSku),
						then: Yup.string()
							.required('Please enter an Android SKU.')
							.max(64, 'Please enter a shorter Android SKU.'),
					}),
			}, [['iosSku', 'androidSku'], ['androidSku', 'iosSku']]),
		)
		.required(),
});

const PriceTierEdit = ({ match }) => {
	const [priceTier, setPriceTier] = useState();
	const { priceTierId } = match.params;

	useEffect(() => {
		const loadPriceTier = async () => {
			const { data: { data: priceTier } } = await axios.get(`/api/v1/admin/pricetier/${priceTierId}`);
			setPriceTier(priceTier);
		}
		loadPriceTier();
	}, [priceTierId]);

	const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
		// Validate discounts, a type can only appear once
		if (values.discounts?.length > 0) {
			const types = [];
			for (var i = 0; i < values.discounts.length; ++i) {
				const type = values.discounts[i].type;
				if (types.includes(type)) {
					setFieldError(`discounts[${i}].type`, 'Every type can only be used once');
					return;
				}
				types.push(type);
			}
		}
		const priceTierToStore = {
			description: values.description,
			discounts: values.discounts,
		};
		try {
			const { data: { data: priceTier } } = await axios.put(`/api/v1/admin/pricetier/${priceTierId}`, priceTierToStore);
			setPriceTier(priceTier);
			toast.success('Price tier "' + priceTierId + '" successfully updated.');
		} catch {
			toast.error('An unknown error occurred!');
		} finally {
			setSubmitting(false);
		}
	};

	const handleNewPriceTier = () => {
		setPriceTier({
			...priceTier,
			discounts: [
				...priceTier.discounts,
				{
					id: '',
					type: '',
					androidSku: '',
					iosSku: '',
					priceTierId: priceTier.id,
				},
			],
		});
	};

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Edit Price Tier {priceTier?.id}</h1>
				</div>
			</div>
			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					{priceTier && (
						<Formik
							initialValues={priceTier}
							validationSchema={PriceTierEditSchema}
							validateOnBlur={false}
							onSubmit={handleSubmit}
							enableReinitialize
						>
							{({ isSubmitting, values }) => (
								<Form disabled={isSubmitting}>
									<div className="divide-y divide-gray-200">
										<div>
											<div className="grid grid-cols-2 gap-x-4 pb-4">
												<Input id="id" label="ID" name="id" disabled />
												<Input id="creationDateTime" label="Created" name="creationDateTime" disabled />
											</div>
											<Input id="description" label={<span>Description<sup>*</sup></span>} name="description" />
											<div className="flex items-center justify-between pt-8">
												<h2 className="text-lg leading-6 font-medium text-gray-900">Discounts</h2>
												<div className="mt-3 flex sm:mt-0 sm:ml-4">
													<button
														type="button"
														className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
														onClick={handleNewPriceTier}
													>
														Add new...
													</button>
												</div>
											</div>
											{values.discounts?.length > 0 ? (
												<div className="divide-y divide-gray-200">
													{values.discounts.map((discount, index) => (
														<div key={`discount-${index}-${discount.id}`} className="py-4 grid grid-cols-6 gap-x-4">
															<Input id={`discounts[${index}].id`} label="ID" name={`discounts[${index}].id`} disabled />
															<Select id={`discounts[${index}].type`} label={<span>Type<sup>*</sup></span>} name={`discounts[${index}].type`} disabled={isSubmitting}>
																<option value="" disabled>Please select...</option>
																<option value="Main">Main</option>
																<option value="Discount10">Discount10</option>
															</Select>
															<div className="col-span-4">
																<div className="grid grid-cols-2 gap-4">
																	<Input className="col-span-1" id={`discounts[${index}].iosSku`} label="iOS SKU" name={`discounts[${index}].iosSku`} disabled={isSubmitting} />
																	<Input className="col-span-1" id={`discounts[${index}].androidSku`} label="Android SKU" name={`discounts[${index}].androidSku`} disabled={isSubmitting} />
																	{!discount.iosSku && !discount.androidSku && (
																		<p className="col-span-2 text-sm text-gray-500">
																			If SKUs are empty, this discount is considered to be free of charge.
																		</p>
																	)}
																</div>
															</div>
														</div>
													))}
												</div>
											) : (
												<p className="text-sm text-gray-500 pb-4">
													No discounts configured yet.
												</p>
											)}
										</div>
										<div className="bt-1 pt-8">
											<div className="flex justify-end">
												<Link
													as="button"
													to={ROUTES.PRICE_TIER_LIST}
													className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Cancel
												</Link>
												<button
													type="submit"
													className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
												>
													Save
												</button>
											</div>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					)}
				</div>
			</div>
		</div>
	);
};

export default PriceTierEdit;
