import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import axios from '../../api/axios';
import { omitNullish } from '../../utils';
import ChoiceColumn from '../../components/choices/ChoiceColumn';

const Choices = () => {
	const [choices, setChoices] = useState();
	const [selectedChoices, setSelectedChoices] = useState([null]);
	const [planConfigs, setPlanConfigs] = useState([]);

	const loadChoicesAsync = async () => {
		try {
			const { data: { data: result } } = await axios.get('/api/v1/admin/choice', {
				params: {
					status: 'Active',
					page: 0,
					size: 999,
				},
			});
			setChoices(result);
		} catch (e) {
		}
	};

	useEffect(() => {
		loadChoicesAsync();
	}, []);

	useEffect(() => {
		const loadPlanConfigsAsync = async () => {
			try {
				const { data: { data: result } } = await axios.get('/api/v1/admin/planconfig', {
					params: {
						status: 'Active',
						page: 0,
						size: 999,
					},
				});
				setPlanConfigs(result);
			} catch (e) {
			}
		};
		loadPlanConfigsAsync();
	}, []);

	const onCreate = async (values, { setSubmitting, resetForm }) => {
		try {
			const { data: { data: choice } } = await axios.post('/api/v1/admin/choice', omitNullish(values));
			setChoices([...choices, choice]);
			resetForm();
			toast.success('Choice successfully created.');
		} finally {
			setSubmitting(false);
		}
	};

	const onUpdate = async (values, { setSubmitting }) => {
		try {
			const { data: { data: result } } = await axios.put(`/api/v1/admin/choice/${values.id}`, omitNullish(values));
			setChoices(choices.map((choice) => choice.id === result.id ? result : choice));
			toast.success('Choice successfully updated.');
		} finally {
			setSubmitting(false);
		}
	};

	const onSelect = (choice, level) => {
		setSelectedChoices([...selectedChoices.slice(0, level + 1), choice.id]);
	};

	const onMove = async (choice, direction) => {
		await axios.post(`/api/v1/admin/choice/${choice.id}:${direction === 'up' ? 'moveUp' : 'moveDown'}`);
		loadChoicesAsync();
	};

	const onDelete = async (choice) => {
		await axios.delete(`/api/v1/admin/choice/${choice.id}`);
		loadChoicesAsync();
	};

	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Choices</h1>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="flex flex-row overflow-x-auto">
					{selectedChoices.map((selectedChoice, index) => (
						<ChoiceColumn
							key={`selectedChoice-${selectedChoice}`}
							choices={choices}
							level={index}
							selectedChoices={selectedChoices}
							planConfigs={planConfigs}
							parentId={selectedChoice}
							onCreate={onCreate}
							onUpdate={onUpdate}
							onSelect={onSelect}
							onMove={onMove}
							onDelete={onDelete}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default Choices;
