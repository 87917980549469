import React, { useState, useEffect } from 'react';
import { parse, getYear, getMonth, isBefore, addDays } from 'date-fns';

import { pad } from '../../utils';
import { ISO_DATE } from '../../constants';

import CalendarDay from './CalendarDay';

const CalendarPage = ({ plan, year, month, constrainHeight }) => {
	const [calendarRows, setCalendarRows] = useState([]);

	useEffect(() => {
		// Determine training days
		const trainingDays = {};
		const paused = {};
		const nowDate = new Date();
		if (plan.status !== 'Completed') {
			const pauseDate = plan.status === 'Paused' && parse(plan.pauseDate, ISO_DATE, nowDate);
			for (let trainingDay of plan.details.trainingDays) {
				const trainingDate = parse(trainingDay.date, ISO_DATE, nowDate);
				if (getYear(trainingDate) === year && getMonth(trainingDate) === month) {
					trainingDays[trainingDay.date] = trainingDay.exercises;
					paused[trainingDay.date] = pauseDate && !isBefore(trainingDate, pauseDate);
				}
			}
		}
		// Create rows and columns
		let date = new Date(year, month, 1);
		const numberOfDays = new Date(year, month + 1, 0).getDate();
		let firstDayOfMonth = new Date(year, month, 1).getDay(); // 0-6, Sunday is 0, Monday is 1, and so on.
		firstDayOfMonth = (firstDayOfMonth + 6) % 7; // map to monday=0
		let day = 0;
		let rows = [];
		let row = [];
		while (day < numberOfDays + firstDayOfMonth) {
			const key = `${year}-${pad(month + 1, 2)}-${pad(day - firstDayOfMonth + 1, 2)}`;
			row.push(
				day < firstDayOfMonth
					? null
					: {
							day: day - firstDayOfMonth + 1,
							training: trainingDays[key],
							paused: paused[key],
							date: addDays(date, day - firstDayOfMonth),
					},
			);
			day++;
			if (day % 7 === 0) {
				rows.push(row);
				row = [];
			}
		}
		if (row.length) {
			while (row.length < 7) {
				row.push(null);
			}
			rows.push(row);
		}
		setCalendarRows(rows);
	}, [plan, year, month]);

	return (
		<div className="border-t border-l">
			{calendarRows.map((row, rowIndex) => (
				<div key={`row-${rowIndex}`} className="grid grid-cols-7 gap-0">
					{row.map((day, dayIndex) => (
						<div key={`day-${rowIndex}-${dayIndex}`} className="border-b border-r">
							{day && <CalendarDay plan={plan} day={day} constrainHeight={constrainHeight} />}
						</div>
					))}
				</div>
			))}
		</div>
	);
};

export default CalendarPage;
