import pickBy from 'lodash/fp/pickBy'
import isNil from 'lodash/isNil';
import negate from 'lodash/negate';

const omitNullish = pickBy(negate(isNil));

const pad = (num, size) => {
	let result = num + '';
	while (result.length < size) {
		result = '0' + result;
	}
	return result;
};

export { omitNullish, pad };
