import React, { useState, useEffect } from 'react';
import { useHistory, generatePath, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from '../../api/axios';
import { ROUTES } from '../../constants';
import { Input, Select } from '../../components/forms';

const initialValues = {
	sheetId: '',
	category: '',
	title: '',
	priceTierId: '',
	intensityCategoryId: '',
};

const PlanConfigCreateSchema = Yup.object().shape({
	sheetId: Yup.string()
		.required('Please enter a sheet ID.')
		.length(44, 'A sheet ID must have exactly 44 characters.'),
	category: Yup.string()
		.max(32, 'Please enter a shorter category.'),
	title: Yup.string()
		.required('Please enter a title.'),
	displayTitle: Yup.string()
		.required('Please enter a display title.')
		.max(64, 'Please enter a shorter display title.'),
	displaySubtitle: Yup.string()
		.required('Please enter a display subtitle.')
		.max(255, 'Please enter a shorter display subtitle.'),
	priceTierId: Yup.string()
		.nullable()
		.required('Please select a price tier.'),
	intensityCategoryId: Yup.string()
		.nullable()
		.required('Please select an intensity category.'),
});

const PlanConfigCreate = () => {
	const history = useHistory();
	const [priceTiers, setPriceTiers] = useState([]);
	const [intensityCategories, setIntensityCategories] = useState([]);

	useEffect(() => {
		const loadDataAsync = async () => {
			const [
				{ data: { data: priceTiers } },
				{ data: { data: intensityCategories } },
			 ] = await Promise.all([
				axios.get('/api/v1/admin/pricetier', {
					page: 0,
					size: 999,
				}),
				axios.get(`/api/v1/admin/intensity/category`, {
					params: {
						page: 0,
						size: 999,
					},
				}),
			]);
			setPriceTiers(priceTiers);
			setIntensityCategories(intensityCategories);
		};
		loadDataAsync();
	}, []);

	const handleSubmit = async (values, { setSubmitting }) => {
		const { data: { data: planConfig } } = await axios.post('/api/v1/admin/planconfig', {
			...values,
		});
		setSubmitting(false);
		history.push(generatePath(ROUTES.PLAN_CONFIG_EDIT, {
			planConfigId: planConfig.id
		}));
	};

	return (
		<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Create Plan Configuration</h1>
				</div>
			</div>
			<div className="mx-auto px-4 py-4 sm:px-6 md:px-8">
				<div className="bg-white px-4 py-4 shadow sm:rounded-lg">
					<Formik
						initialValues={initialValues}
						validationSchema={PlanConfigCreateSchema}
						validateOnBlur={false}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form disabled={isSubmitting}>
								<div className="divide-y divide-gray-200">
									<div className="grid gap-x-4 pb-8">
										<Input className="pb-4" id="sheetId" label={<span>Sheet ID<sup>*</sup></span>} name="sheetId" disabled={isSubmitting} />
										<Input className="pb-4" id="category" label={<span>Category<sup>*</sup></span>} name="category" disabled={isSubmitting} />
										<Input className="pb-4" id="title" label={<span>Internal Title<sup>*</sup></span>} name="title" disabled={isSubmitting} />
										<Input className="pb-4" id="displayTitle" label={<span>Display Title<sup>*</sup></span>} name="displayTitle" disabled={isSubmitting} />
										<Input className="pb-4" id="displaySubtitle" label={<span>Display Subtitle<sup>*</sup></span>} name="displaySubtitle" disabled={isSubmitting} />
										<Select className="pb-4" id="priceTierId" label={<span>Price Tier<sup>*</sup></span>} name="priceTierId" disabled={isSubmitting}>
											<option>Please select...</option>
											{priceTiers.map(priceTier => (
												<option key={priceTier.id} value={priceTier.id}>{priceTier.description}</option>
											))}
										</Select>
										<Select className="pb-4" id="intensityCategoryId" label={<span>Intensity Category<sup>*</sup></span>} name="intensityCategoryId" disabled={isSubmitting}>
											<option>Please select...</option>
											{intensityCategories.map((intensityCategory) => (
												<option key={intensityCategory.id} value={intensityCategory.id}>{intensityCategory.title}</option>
											))}
										</Select>
									</div>
									<div className="bt-1 pt-8">
										<div className="flex justify-end">
											<Link
												as="button"
												to={ROUTES.PLAN_CONFIG_LIST}
												className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Cancel
											</Link>
											<button
												type="submit"
												className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
											>
												Create
											</button>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default PlanConfigCreate;
