import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Pagination from './Pagination';
import axios from '../../api/axios';
import { format, parseISO } from 'date-fns';

const initializeFilter = (filters, initialFilters) => {
	const result = {};
	filters.map((filter) => {
		if (filter.type === 'text') {
			result[filter.field] = '';
		} else if (filter.type === 'select') {
			result[filter.field] = '';
		} else if (filter.type === 'date') {
			result[filter.field] = '';
		}
		return null;
	});
	if (initialFilters) {
		for (let initialFilter of initialFilters) {
			if (result.hasOwnProperty(initialFilter.field)) {
				result[initialFilter.field] = initialFilter.value;
			}
		}
	}
	return result;
}

/*
	field
	filter = text|select
	filterField
	filterValues = [{ value, label }, ...]
	cell = fn(value,...)
	className
 */
const Table = ({ url, columns, defaultParams = {}, filters = [], initialFilters, onEditClick }) => {
	const [currentDefaultParams] = useState(defaultParams);
	const [items, setItems] = useState([]);
	const [pagination, setPagination] = useState();
	const [pageIndex, setPageIndex] = useState(0);
	const [filter, setFilter] = useState(() => initializeFilter(filters, initialFilters));
	const [appliedFilter, setAppliedFilter] = useState({ ...filter});
	const [hasFilter, setHasFilter] = useState(false);

	useEffect(() => {
		const loadItemsAsync = async () => {
			const { data: { data: newItems, meta: newPagination } } = await axios.get(url, {
				params: {
					...currentDefaultParams,
					...appliedFilter,
					page: pageIndex,
				},
			});
			setItems(newItems);
			setPagination(newPagination);
		};
		loadItemsAsync();
	}, [url, appliedFilter, pageIndex, currentDefaultParams]);

	const updateFilter = (column, value) => {
		setFilter({
			...filter,
			[column.field]: value,
		});
	};

	const applyFilter = (evt) => {
		evt.preventDefault();
		setAppliedFilter(filter);
		setHasFilter(true);
	};

	const clearFilter = () => {
		const emptyFilter = initializeFilter(filters);
		setAppliedFilter({ ...emptyFilter });
		setFilter({ ...emptyFilter });
		setHasFilter(false);
	};

	const handleSelectChange = (column, e) => {
		updateFilter(column, e.target.value);
	};

	const handlePageChange = (newPage) => {
		setPageIndex(newPage);
	};

	const renderCell = (item, index, column) => {
		if (column.cell) {
			return column.cell(item[column.field], item, column, index);
		}
		const value = item[column.field];
		switch (column.format) {
			case 'timestamp':
				return format(parseISO(value), 'dd/MM/yyyy HH:mm:ss');
			default:
				return value;
		}
	};

	return (
		<div className="flex flex-col">
			<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				{filters && filters.length > 0 && (
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<form onSubmit={applyFilter}>
							{filters.map((filterOption) => (
								<div key={`filter-${filterOption.field}`} className="inline-block mr-2">
									<label htmlFor={`filter-${filterOption.field}`} className="block text-sm font-medium text-gray-700">
										{filterOption.label || columns.find((column) => column.field === filterOption.field).label}
									</label>
									<div className="mt-1">
										{filterOption.type === 'text' &&
											<input
												type="text"
												name={`filter-${filterOption.field}`}
												id={`filter-${filterOption.field}`}
												className="appearance-none block w-full px-3 py-2 shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md"
												value={filter[filterOption.field]}
												onChange={(e) => updateFilter(filterOption, e.target.value)}
											/>
										}
										{filterOption.type === 'select' &&
											<select
												name={`filter-${filterOption.field}`}
												id={`filter-${filterOption.field}`}
												className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
												value={filter[filterOption.field]}
												onChange={(e) => handleSelectChange(filterOption, e)}
											>
												{filterOption.values.map((filterValue) => (
													<option key={filterValue.value} value={filterValue.value}>{filterValue.label}</option>
												))}
											</select>
										}
										{filterOption.type === 'date' &&
											<input
												type="date"
												name={`filter-${filterOption.field}`}
												id={`filter-${filterOption.field}`}
												className="appearance-none block w-full px-3 py-2 shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm border-gray-300 rounded-md"
												value={filter[filterOption.field]}
												onChange={(e) => updateFilter(filterOption, e.target.value)}
											/>
										}
									</div>
								</div>
							))}
							<button
								type="submit"
								className="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
							>
								Filter
							</button>
							<button
								type="button"
								className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
								onClick={clearFilter}
							>
								Clear
							</button>
						</form>
					</div>
				)}
				<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									{columns.map((column) => (
										<th
											key={`header-${column.field}`}
											scope="col"
											className={classNames(
												'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
												column.headerClassName,
											)}
										>
											{column.label}
										</th>
									))}
									<th scope="col" className="relative px-6 py-3">
										<span className="sr-only">Edit</span>
									</th>
								</tr>
							</thead>
							{items.length > 0 && (
								<tbody>
									{items.map((item, index) => (
										<tr key={`row-${item.id}`} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
											{columns.map((column) => (
												<td
													key={`row-${item.id}-column-${column.field}`}
													className={classNames(
														'px-6 py-4 whitespace-nowrap text-sm text-gray-500',
														column.cellClassName,
													)}
												>
													{renderCell(item, index, column)}
												</td>
											))}
											<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
												<button className="text-cyan-600 hover:text-cyan-900" onClick={() => onEditClick(item, index)}>Edit</button>
											</td>
										</tr>
									))}
								</tbody>
							)}
							{items.length === 0 && (
								<tbody>
									<tr>
										<td colSpan={columns.length + 1} className="h-96 bg-white">
											<div className="flex items-center justify-center text-bold text-gray-500">
												{hasFilter ? 'Your filter did not yield any results.' : 'No items.'}
											</div>
										</td>
									</tr>
								</tbody>
							)}
						</table>
						{/* Pagination */}
						<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
							<div className="flex-1 flex justify-between sm:hidden">
								<button
									className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
								>
									Previous
								</button>
								<button
									className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
								>
									Next
								</button>
							</div>
							<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
								<div>
									<p className="text-sm text-gray-700">
										{pagination && (
											<>
												Showing <span className="font-medium">{1 + pagination.pageIndex * pagination.itemsPerPage}</span> to{' '}
												<span className="font-medium">{pagination.pageIndex * pagination.itemsPerPage + pagination.currentItemCount}</span>{' '}
												of <span className="font-medium">{pagination.totalItems}</span> results
											</>
										)}
									</p>
								</div>
								{pagination && <Pagination {...pagination} onPageChange={handlePageChange} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Table;
