import React from 'react';
import classNames from 'classnames';

import './Avatar.css';

const Avatar = ({ runner, className }) => {
	if (runner?.profileImageUrl) {
		return (
			<div className={classNames('Avatar relative', className)}>
				<img
					className={classNames('rounded-full', className)}
					src={runner?.profileImageUrl}
					alt=""
				/>
				<span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
			</div>
		);
	} else {
		return (
			<div className={classNames('Avatar relative', className)}>
				<div className={classNames('rounded-full bg-white flex justify-center items-center', className)}>
					<div className="w-10/12 h-10/12 opacity-50">
						<svg className="object-fill" x="0px" y="0px" viewBox="0 0 24 24">
							<path d="M18.98,12.3c-0.07-0.35-0.32-0.63-0.66-0.75l-3.81-1.3l2.43-7.44c0.14-0.43-0.03-0.9-0.41-1.15 c-0.38-0.25-0.88-0.21-1.21,0.09l-10,9c-0.26,0.24-0.38,0.6-0.31,0.94c0.07,0.35,0.32,0.63,0.66,0.75l3.81,1.3l-2.43,7.44 c-0.14,0.43,0.03,0.9,0.41,1.15C7.62,22.45,7.81,22.5,8,22.5c0.24,0,0.48-0.09,0.67-0.26l10-9C18.93,13.01,19.05,12.65,18.98,12.3z" />
						</svg>
					</div>
				</div>
				<span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
			</div>
		);
	}
};

export default Avatar;
