import axios from 'axios';

let accessTokenHandler = null;
let unauthorizedErrorHandler = null;

const client = axios.create();
client.interceptors.request.use((config) => {
	if (accessTokenHandler) {
		config = accessTokenHandler(config);
	}
	return config;
});
client.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if (error.response.status === 401 && unauthorizedErrorHandler) {
		unauthorizedErrorHandler(error);
	}
	return Promise.reject(error);
});

export function setAccessTokenHandler(handler) {
	//client.defaults.headers.common.Authorization = `Bearer ${token}`;
	accessTokenHandler = handler;
}

export function setUnauthorizedErrorHandler(handler) {
	unauthorizedErrorHandler = handler;
}

export default client;
