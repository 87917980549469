import React, { useState, useEffect } from 'react';
import { useHistory, generatePath, Link } from 'react-router-dom';

import axios from '../../api/axios';
import { Table } from '../../components/table';
import { ROUTES } from '../../constants';

const AssignedToCell = ({ value }) => {
	const [admin, setAdmin] = useState();

	useEffect(() => {
		const loadAdminAsync = async () => {
			const { data: { data: loadedAdmin } } = await axios.get(`/api/v1/admin/admins/${value.id}`);
			setAdmin(loadedAdmin);
		};
		if (value) {
			loadAdminAsync();
		}
	}, [value]);

	if (admin) {
		return  <Link className="underline" to={generatePath(ROUTES.ADMIN_EDIT, { adminId: value.id })}>{admin.firstName}</Link>;
	} else if (value) {
		return <Link className="underline" to={generatePath(ROUTES.ADMIN_EDIT, { adminId: value.id })}>Admin {value.id}</Link>;
	} else {
		return null;
	}
};

const columns = [
	{
		field: 'creationDateTime',
		label: 'Created',
		format: 'timestamp',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'identifier',
		label: 'Identifier',
	},
	{
		field: 'value',
		label: 'Value',
	},
	{
		field: 'comment',
		label: 'Comment',
		cell: (value) => {
			if (!value) {
				return value;
			}
			let text = value.replace(/(?:\r\n|\r|\n)/g, ' ');
			if (text.length > 100) {
				text = text.substring(0, 100) + '…';
			}
			return text;
		},
	},
	{
		field: 'author',
		label: 'Author',
		cell: (value) => <Link className="underline" to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: value.id })}>Runner {value.id}</Link>,
	},
	{
		field: 'status',
		label: 'Status',
	},
	{
		field: 'assignedTo',
		label: 'Assigned to',
		cell: (value) => <AssignedToCell value={value} />,
	},
];

const filters = [
	{
		field: 'fromDate',
		type: 'date',
		label: 'From date',
	},
	{
		field: 'untilDate',
		type: 'date',
		label: 'Until date',
	},
	{
		field: 'identifier',
		type: 'text',
	},
	{
		field: 'value',
		type: 'select',
		values: [
			{ value: '', label: '(Please select...)' },
			{ value: 'Negative', label: 'Negative' },
			{ value: 'Neutral', label: 'Neutral' },
			{ value: 'Positive', label: 'Positive' },
		],
	},
	{
		field: 'status',
		type: 'select',
		values: [
			{ value: '', label: '(Please select...)' },
			{ value: 'New', label: 'New' },
			{ value: 'Assigned', label: 'Assigned' },
			{ value: 'Closed', label: 'Closed' },
		],
	}
];

const ExerciseFeedbackList = () => {
	const history = useHistory();
	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Exercise Feedback</h1>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="py-4">
					<Table
						url="/api/v1/admin/exercise/feedback"
						columns={columns}
						filters={filters}
						onEditClick={(row) => history.push(generatePath(ROUTES.EXERCISE_FEEDBACK_EDIT, { exerciseFeedbackId: row.id }))}
					/>
				</div>
			</div>
		</>
	);
};

export default ExerciseFeedbackList;
