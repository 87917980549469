import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import { Input, Typeahead, TextArea } from '../forms';

const ChoiceSchema = Yup.object().shape({
	title: Yup.string()
		.when('planConfigId', {
			is: planConfigId => !Boolean(planConfigId),
			then: Yup.string()
					.required('Please select a title.')
					.max(64, 'Please enter a shorter title.'),
		}),
	subtitle: Yup.string()
		.when('planConfigId', {
			is: planConfigId => !Boolean(planConfigId),
			then: Yup.string()
					.required('Please enter a subtitle.')
					.max(255, 'Please enter a shorter subtitle.'),
		}),
	badge: Yup.string()
		.max(32, 'Please enter a shorter badge.'),
});

const ChoiceItem = ({ className, parentId, selected = false, level, onSubmit, onSelect, onMove, onDelete, choice, submitLabel = 'Update', planConfigs }) => {
	const initialValues = {
		title: '',
		subtitle: '',
		parentId,
		...choice,
		badge: choice?.badge || '',
		type: 'Choice',
	};

	const handleSelect = () => {
		onSelect(choice, level);
	};

	return (
		<div className={classNames('bg-white shadow-sm rounded-md p-4 m-2 mb-4', selected && 'ring-2 ring-offset-2 ring-cyan-500')}>
			<Formik
				initialValues={initialValues}
				validationSchema={ChoiceSchema}
				validateOnBlur={false}
				onSubmit={onSubmit}
				enableReinitialize
			>
				{({ isSubmitting, values }) => (
					<Form disabled={isSubmitting}>
						{onMove && (
							<div className="Buttons">
								{/* <a className="mr-2" href="#" onClick={() => onMove(choice, 'up')}>
									<FontAwesomeIcon icon={faCaretUp} />
								</a>
								<a className="mr-4" href="#" onClick={() => onMove(choice, 'down')}>
									<FontAwesomeIcon icon={faCaretDown} />
								</a>
								<a href="#" onClick={() => onDelete(choice)}>
									<FontAwesomeIcon icon={faTrashAlt} />
								</a> */}
							</div>
						)}
						<Typeahead
							className="pb-4"
							id="planConfigId"
							label="Plan Config"
							name="planConfigId"
							disabled={isSubmitting}
							labelKey="title"
							options={planConfigs}
							selectionToValue={(selection) => selection?.id}
							valueToSelection={(value) => planConfigs.find((planConfig) => planConfig.id === value)}
							clearButton
						/>
						{values.planConfigId && (
							<Input className="pb-4" id="badge" label={<span>Badge</span>} name="badge" disabled={isSubmitting} />
						)}

						{!values.planConfigId && (
							<>
								<Input className="pb-4" id="title" label={<span>Title<sup>*</sup></span>} name="title" disabled={isSubmitting || values.planConfigId} />
								<TextArea className="pb-4" id="subtitle" label={<span>Subtitle<sup>*</sup></span>} name="subtitle" disabled={isSubmitting || values.planConfigId} />
							</>
						)}

						<div className="flex justify-between">
							<button
								className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting ? 'Please wait...' : submitLabel}
							</button>
							{choice && choice.id && !choice.planConfigId &&
								<button
									className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
									type="button"
									disabled={isSubmitting}
									onClick={handleSelect}
								>
									Select...
								</button>
							}
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ChoiceItem;
