import React from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { Table } from '../../components/table';
import { ROUTES } from '../../constants';

const columns = [
	{
		field: 'id',
		label: 'ID',
		cellClassName: 'font-medium text-gray-900',
	},
	{
		field: 'creationDateTime',
		label: 'Created',
		format: 'timestamp',
	},
	{
		field: 'entity',
		label: 'Entity',
		cell: (value) => {
			if (value.type === 'Plan') {
				return <Link to={generatePath(ROUTES.PLAN_EDIT, { planId: value.id })}>Plan {value.id}</Link>;
			} else {
				return `${value.type} ${value.id}`;
			}

		},
	},
	{
		field: 'owner',
		label: 'Owner',
		cell: (value) => {
			if (value.type === 'Runner') {
				return <Link to={generatePath(ROUTES.RUNNER_EDIT, { runnerId: value.id })}>Runner {value.id}</Link>;
			} else {
				return `${value.type} ${value.id}`;
			}
		},
	},
	{
		field: 'price',
		label: 'Price',
		cell: (_, row) => {
			return `${row.price} ${row.currency}`;
		},
	},
	{
		field: 'status',
		label: 'Status',
	},
	{
		field: 'type',
		label: 'Type',
	},
];

const filters = [
	{
		field: 'fromDate',
		type: 'date',
		label: 'From date',
	},
	{
		field: 'untilDate',
		type: 'date',
		label: 'Until date',
	},
	{
		field: 'type',
		type: 'select',
		values: [
			{ value: '', label: '(please select)' },
			{ value: 'GooglePlayStore', label: 'GooglePlayStore' },
			{ value: 'AppleAppStore', label: 'AppleAppStore' },
		],
	}
];

const initialFilters = [
	{ field: 'fromDate', value: '2021-07-14' },
];

const PurchaseList = () => {
	const history = useHistory();
	return (
		<>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
					<h1 className="text-2xl font-semibold text-gray-900">Purchases</h1>
					<div className="mt-3 flex sm:mt-0 sm:ml-4">

					</div>
				</div>
			</div>
			<div className="mx-auto px-4 sm:px-6 md:px-8">
				<div className="py-4">
					<Table
						url="/api/v1/admin/purchase"
						columns={columns}
						filters={filters}
						initialFilters={initialFilters}
						onEditClick={(row) => history.push(generatePath(ROUTES.PURCHASE_EDIT, { purchaseId: row.id }))}
					/>
				</div>
			</div>
		</>
	);
};

export default PurchaseList;
