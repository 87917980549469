import React from 'react';
import { Switch as HeadlessUiSwitch } from '@headlessui/react';
import { ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

const Switch = (props) => {
	const [,meta, helpers] = useField(props);
	const { className, id, label, name, disabled } = props;
	const { touched, error, value } = meta;
	const { setValue } = helpers;

	const handleChange = (newValue) => {
		if (!disabled) {
			setValue(newValue);
		}
	};

	return (
		<div className={className}>
			<HeadlessUiSwitch.Group as="div" className="flex items-center">
				<HeadlessUiSwitch
					checked={value}
					onChange={handleChange}
					className={classNames(
						value ? 'bg-cyan-600' : 'bg-gray-200',
						'mt-1 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500',
						disabled ? 'opacity-50 cursor-default' : 'cursor-pointer',
					)}
					id={id}
				>
					<span className="sr-only">Use setting</span>
					<span
						className={classNames(
							value ? 'translate-x-5' : 'translate-x-0',
							'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
						)}
					>
						<span
							className={classNames(
								value ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
								'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
							)}
							aria-hidden="true"
						>
							<svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
								<path
									d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
									stroke="currentColor"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span
							className={classNames(
								value ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
								'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
							)}
							aria-hidden="true"
						>
							<svg className="h-3 w-3 text-cyan-600" fill="currentColor" viewBox="0 0 12 12">
								<path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
							</svg>
						</span>
					</span>
				</HeadlessUiSwitch>
				<HeadlessUiSwitch.Label as="span" className="ml-3">
					<label htmlFor={id} className="block text-sm font-medium text-gray-700">{label}</label>
				</HeadlessUiSwitch.Label>
			</HeadlessUiSwitch.Group>
			{touched && error && (
				<p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
					<ErrorMessage name={name} />
				</p>
			)}
		</div>
	);
};

export default Switch;
