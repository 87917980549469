import React, { useState, Fragment } from 'react';
import { ErrorMessage, useField } from 'formik';
import { Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const defaultConverter = (value) => value;

const Typeahead = (props) => {
	const [open, setOpen] = useState(false);
	const [,meta, helpers] = useField(props);
	const { touched, error, value } = meta;
	const { setValue, setTouched } = helpers;
	const { className, id, label, hideLabel, name, required, disabled, options, readonly, labelKey, clearButton, selectionToValue = defaultConverter, valueToSelection = defaultConverter, ...rest } = props;
	const [textValue, setTextValue] = useState(() => {
		const vts = valueToSelection(value);
		if (vts) {
			return vts[labelKey];
		} else {
			return '';
		}
	});

	const handleFocus = () => {
		if (!disabled && !readonly) {
			setOpen(true);
		}
	};

	const handleBlur = () => {
		setOpen(false);
		setTouched(true);
	};

	const handleChange = (selection) => {
		setValue(selectionToValue(selection));
		setTextValue(selection[labelKey]);
	};

	const handleClear = () => {
		setValue('');
		setTextValue('');
	};

	return (
		<div className={classNames(className, 'relative')}>
			{!hideLabel && (
				<label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
					{label}
				</label>
			)}
			<div className="relative rounded-md shadow-sm">
				<input
					id={id}
					name={id}
					type="text"
					autoComplete={id}
					required={required}
					disabled={disabled}
					readOnly={readonly}
					className={classNames(
						'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm sm:text-sm',
						touched && error ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500',
						(disabled || readonly) && 'opacity-50',
					)}
					aria-invalid={Boolean(touched && error)}
					aria-describedby={`${id}-error`}
					{...rest}
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={textValue}
					onChange={(e) => setTextValue(e.target.value)}
				/>
				{touched && error && (
					<div className={
						classNames(
							clearButton && textValue ? 'right-6' : 'right-0',
							'absolute inset-y-0 pr-3 flex items-center pointer-events-none',
						)
					}>
						<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
				)}
				{clearButton && textValue && !disabled && !readonly && (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center">
						<button type="button" onClick={handleClear}><XIcon className="h-5 w-5 text-gray-900" aria-hidden="true" /></button>
					</div>
				)}
			</div>
			<Transition
				show={open}
				as={Fragment}
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<ul
					className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
				>
					{options
						.filter((option) => option[labelKey].indexOf(textValue) > -1)
						.map((option) => (
							<li
								key={option.id}
								className="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-cyan-600"
								value={option.id}
								onClick={() => handleChange(option)}
							>
								<span className={classNames(value === option.id ? 'font-semibold' : 'font-normal', 'block truncate')}>
									{option[labelKey]}
								</span>
								{value === option.id ? (
									<span
										className={classNames(
											false ? 'text-white' : 'text-cyan-600',
											'absolute inset-y-0 left-0 flex items-center pl-1.5'
										)}
									>
										<CheckIcon className="h-5 w-5" aria-hidden="true" />
									</span>
								) : null}
							</li>
						))
					}
				</ul>
			</Transition>
			{touched && error && (
				<p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
					<ErrorMessage name={name} />
				</p>
			)}
		</div>
	);
};

export default Typeahead;
