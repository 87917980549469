import React, { forwardRef } from 'react';
import { ErrorMessage, useField } from 'formik';
import ReactJson from 'react-json-view';

const JsonField = (props) => {
	const [field, meta] = useField(props);
	const { className, id, label, name, hideLabel = false, ...rest } = props;
	const { touched, value, error } = meta;

	const JsonFormControl = forwardRef(({ children, onChange}, ref) => (
		<ReactJson src={value} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} />
	));

	return (
		<div className={className}>
			{!hideLabel && (
				<label htmlFor={id} className="block text-sm font-medium text-gray-700">
					{label}
				</label>
			)}
			<div className="mt-1 relative rounded-md shadow-sm">
				<JsonFormControl {...field} {...rest} source={value} />
			</div>

			{touched && error && (
				<p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
					<ErrorMessage name={name} />
				</p>
			)}
		</div>
	)
};

export default JsonField;
