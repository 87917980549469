import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as sessionsApi from '../api/sessions';
import * as usersApi from '../api/users';
import { setAccessTokenHandler, setUnauthorizedErrorHandler } from '../api/axios';
import { ROUTES } from '../constants';

const STORAGE_KEY = 'hirunner-admin';

const AuthContext = createContext({});
let refreshTimer;

const determineNextRefresh = (expiresInMs) => {
	return Math.min(20000, expiresInMs);
}

const loadSessionFromStorage = () => {
	try {
		return JSON.parse(localStorage.getItem(STORAGE_KEY));
	} catch {
		return undefined;
	}
}

const writeSessionToStorage = (session) => {
	try {
		if (session) {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(session));
		} else {
			localStorage.removeItem(STORAGE_KEY);
		}
	} catch (_) {
	}
}

export function AuthProvider({ children }) {
	const [user, setUser] = useState();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingInitial, setLoadingInitial] = useState(true);
	const history = useHistory();
	const location = useLocation();

	// Forward declarations
	let logout, refreshToken;

	useEffect(() => {
		setError(null);
	}, [location.pathname]);

	useEffect(() => {
		setAccessTokenHandler((config) => {
			const session = loadSessionFromStorage();
			if (session && session.access_token) {
				config.headers['Authorization'] = `Bearer ${session.access_token}`;
			}
			return config;
		});
		setUnauthorizedErrorHandler(() => logout());
	}, [logout]);

	const loadUserAsync = useCallback(async () => {
		try {
			const { data: { data: user } } = await usersApi.getCurrentUser();
			setUser(user);
		} catch {
			// ignore
		} finally {
			setLoadingInitial(false);
		}
	}, []);

	useEffect(() => {
		const initializeAsync = async () => {
			// Try to fetch access token from localStorage; if present, try to load user
			const session = loadSessionFromStorage();
			try {
				if (session && session.access_token && session.refresh_token && session.expiresAt) {
					const deltaToExpiry = session.expiresAt - Date.now();
					if (deltaToExpiry > 0) {
						refreshTimer = setTimeout(() => {
							refreshToken();
						}, determineNextRefresh(deltaToExpiry));
						await loadUserAsync();
					} else {
						console.log('Stored credentials were already expired!');
						logout();
					}
				} else {
					logout();
				}
			} catch {
				logout();
			}
		};
		initializeAsync();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadUserAsync]);

	const login = useCallback(async (email, password) => {
		clearTimeout(refreshTimer);
		setLoading(true);
		try {
			const session = await sessionsApi.login(email, password);
			const newSession = {
				...session,
				expiresAt: Date.now() + session.expires_in * 1000,
			};
			writeSessionToStorage(newSession);
			await loadUserAsync();
			refreshTimer = setTimeout(() => {
				refreshToken();
			}, determineNextRefresh(1000 * (newSession.expires_in - 10)));
			history.push(ROUTES.DASHBOARD);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}, [history, loadUserAsync, refreshToken]);

	logout = useCallback(async () => {
		clearTimeout(refreshTimer);
		writeSessionToStorage();
		setUser(undefined);
		setLoading(false);
		setLoadingInitial(false);
		history.push(ROUTES.LOGIN);
	}, [history]);

	refreshToken = useCallback(async () => {
		const session = loadSessionFromStorage();
		if (!session || !session.refresh_token) {
			return;
		}
		try {
			const { data: updatedSession } = await sessionsApi.refresh(session.refresh_token);
			const newSession = {
				...updatedSession,
				expiresAt: Date.now() + updatedSession.expires_in * 1000,
			};
			writeSessionToStorage(newSession);
			refreshTimer = setTimeout(() => {
				console.log('Refresh token after reschedule at ', new Date().getTime());
				refreshToken();
			}, determineNextRefresh(1000 * (updatedSession.expires_in - 10)));
		} catch {
			logout();
		}

	}, [refreshToken, logout]);

	const memoedValue = useMemo(
		() => ({
			user,
			loading,
			error,
			login,
			logout,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[login, user, loading, error],
	);

	return (
		<AuthContext.Provider value={memoedValue}>
			{!loadingInitial && children}
		</AuthContext.Provider>
	);
}

export default function useAuth() {
	return useContext(AuthContext);
}
